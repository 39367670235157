
shopJS.promotion={
    /*painted_eggshell:function(){
        const $modal = $('#paintedEggshellModal');
        const $btn = $('.btn-paintedEggshell');
        if(!$modal.length) return

        const $egg_img = $modal.find('.egg_img');
        const TAG = 'paintedEggshell';
        const next_img = $egg_img.attr('src');
        const tmp_img = new Image();
        tmp_img.src = next_img;

        if (!Cookies.get(TAG) && window['allowRunPaintedEggshellJS']) {
            $btn.removeClass('hide');
        }
        tmp_img.onload=function(){
            $btn.one('click',function () {
                let award = duangduangduang();
                $egg_img.siblings('.money').text(`$${award[0]}`).removeClass('hide')
                        .siblings('.code').text(award[1]).removeClass('hide');

                $modal.modal('show');
                Cookies.set(TAG, 1);
                $btn.addClass('hide');
            })
        }

        function duangduangduang() {
            const awards = [
                [3,'SILVEREGG'],[4,'HAPPYEGG'],[5,'RAINBOWNEGG']
            ];
            let result;
            let randomNum =Math.floor(Math.random() * 10);
            switch(randomNum){
                case 9:
                    result = 2;
                    break;
                case 8:
                case 7:
                case 6:
                    result = 1;
                    break;
                default:
                    result = 0;
                    break;
            }
            return awards[result]
        }
    },

    blackfriday: function () {
        const $modal = $('#customSalePosterModal');
        const $sideBtn = $('.btn_sale_poster_fixed');
        const cookie_key = 'blackfriday';
        const cookie_days = 10;
        if(!$modal.length) return;

        $modal.removeClass('hide');
        $sideBtn.removeClass('hide');

        const $main_img = $modal.find('.main_img');
        const next_img = $main_img.data('next-src');
        const tmp_img = new Image();
        tmp_img.src = next_img;

        let cookieStr = (shopJS.common.cookie)[cookie_key];
        let cookieObj = {};

        if(typeof cookieStr === 'undefined') {
            cookieObj = {
                is_close: 0, /!*user close*!/
                is_clicked: 0
            }

            shopJS.common.cookie = {
                key: cookie_key,
                value: JSON.stringify(cookieObj),
                days: cookie_days
            }
        }else {
            cookieObj = JSON.parse(cookieStr);
        }

        if(cookieObj.is_clicked === 1) {
            $modal.removeClass('init');
            $main_img.attr('src',next_img);
        }

        tmp_img.onload=()=>{
            if (cookieObj.is_clicked === 0) {
                $main_img.parent().one('click',function () {
                    const $this = $(this).find('.main_img');
                    shopJS.common.cookie = {
                        key: cookie_key,
                        value: JSON.stringify(Object.assign(cookieObj,{is_clicked:1})),
                        days: cookie_days
                    }
                    $this.attr('src',next_img);
                })
            }
        }

        $modal.on('hide.bs.modal',()=>{
            // console.log(cookieObj);
            shopJS.common.cookie = {
                key: cookie_key,
                value: JSON.stringify(Object.assign(cookieObj,{is_close:1})),
                days: cookie_days
            }
        })

        if(cookieObj.is_close === 0) {
            $modal.modal('show');
        }

        $sideBtn.on('click', function(){
            $modal.modal('show');
        })

    }*/
}




