/**
 * Created by Administrator on 2017/5/5.
 */



shopJS.index={
    init() {
        // shopJS.static.indexStatic();
        shopJS.static.categoryStatic();
        shopJS.index.sliderInit();
        shopJS.index.recommendCategoryInit();
        shopJS.index.middleSliderInit();
        shopJS.index.advantageSliderInit();
        shopJS.index.recommendProductInit();
        shopJS.index.headerOpacity();
        shopJS.googleAds.googleAds('home');
    },

    sliderInit () {
        const $slide = $('.top-slide li.swiper-slide');
        let options = {
            autoplay: {
                delay: 5000,
            },
            watchOverflow: true,
        };

        if($slide.length > 1) {
            options.loop = true;
            options.pagination = {
                el: '.top-slide .swiper-pagination'
            }
            delete options.watchOverflow;
        }

        new Swiper('#top-slide',options);
    },

    recommendCategoryInit() {
        const rItem = $('.recommend-category .r-item');

        rItem.each(function (index){
            let id = `r-item-${index}`
            $(this).attr('id',id)
            let options = {
                watchOverflow: true,
                navigation: {
                    prevEl: `#${id} .swiper-button-prev`,
                    nextEl: `#${id} .swiper-button-next`,
                },
            };

            new Swiper(`#${id} .r-item-slide`,options);
        })

    },


    middleSliderInit () {
        const $slide = $('.middle-slide li.swiper-slide');
        let options = {
            autoplay: {
                delay: 8000,
            },
            watchOverflow: true,
        };

        if($slide.length > 1) {
            options.loop = true;
            options.pagination = {
                el: '.middle-slide-wrap .swiper-pagination'
            }
            delete options.watchOverflow;
        }

        new Swiper('.middle-slide',options);
    },

    advantageSliderInit () {
        let options = {
            loop: true,
            autoplay: {
                delay: 10000,
            },
            watchOverflow: true,
            pagination: {
                el: '.advantage-slide-wrap .swiper-pagination'
            },
        };
        new Swiper('.advantage-slide',options);
    },

    recommendProductInit() {
        let $wrap = $('.recommend-product')
        let $rpHeader = $wrap.find('.rp-header');
        let $rpHeaderList = $rpHeader.find('.list');
        let $rpHeaderSubList = $rpHeader.find('.sublist');
        let $rpProdLists = $wrap.find('.r-prod-list');

        $rpHeaderList.find('.item').on('click',function(){
            let $this = $(this);
            let rpIndex = $this.data('rp-index');
            let $subList = $rpHeaderSubList.filter(`[data-rp-index="${rpIndex}"]`);
            let $curSubItem;

            $this.addClass('on').siblings('.item').removeClass('on');
            if($subList.length > 0){
                $curSubItem = $subList.find('.subitem.on');
                rpIndex = $curSubItem.data('srp-index');
                $subList.addClass('on').siblings('.sublist').removeClass('on');
            }else {
                $rpHeaderSubList.removeClass('on');
            }

            $rpProdLists.filter(`[data-index="${rpIndex}"]`).addClass('on').siblings('.r-prod-list').removeClass('on');

            reScrollTop()
        })

        $rpHeaderSubList.find('.subitem').on('click',function() {
            let $this = $(this);
            let rpIndex = $this.data('srp-index');

            $this.addClass('on').siblings('.subitem').removeClass('on');

            $rpProdLists.filter(`[data-index="${rpIndex}"]`).addClass('on').siblings('.r-prod-list').removeClass('on');

            reScrollTop()

        })

        function reScrollTop() {
            let wrapTop = $wrap.offset().top;
            let winTop = $(window).scrollTop();
            let bannerHeight = $('.top-banner-wrap').height();
            let headerHeight = $('header').hasClass('in')?$('header .inner').height():0;
            let offset = bannerHeight + headerHeight
            // console.log(wrapTop , winTop);
            // console.log(bannerHeight , headerHeight);
            if(wrapTop < winTop+offset){
                $('html,body').animate({
                    scrollTop: wrapTop-offset
                }, 100);
            }
        }



    },

    setrpHeaderTop() {
        let $rpHeader = $('.p-idx .recommend-product .rp-header');
        if ($('header').hasClass('in')) {
            $rpHeader.addClass('under-header')
        }else {
            $rpHeader.removeClass('under-header')
        }
    },

    headerOpacity () {
        const $header=$('header');

        $(window).on('scroll', function () {
            let winTop=$(window).scrollTop();
            if(winTop>=400 && $header.hasClass('opacity')){
                $header.removeClass('opacity');
            }

            if(winTop<400 && !$header.hasClass('opacity')){
                $header.addClass('opacity');
            }
        })
    }
};
