shopJS.common = {
    globalInit() {
        shopJS.common.initImage();
        shopJS.common.bindDrag();
        shopJS.common.bindInputClearBtn();
        shopJS.common.createGoogleTranslate();
        shopJS.common.reportMonitor($('[data-monitor]'))
    },
    backToTop() {
        $('.icon-top').hide();
        $(window).scroll(function () {
            if ($(window).scrollTop() > $(window).height()) {
                $('.icon-top').fadeIn();
            } else {
                $('.icon-top').fadeOut();
            }
        })
        $('.icon-top').on('click', function () {
            $('html,body').animate({
                scrollTop: 0
            }, 200);
        });
    },

    axSetup() {
        $u.http.defaults.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content');
    },

    get cookie() {
        let c_str = document.cookie;
        if (c_str === '') return {};

        let c_arr = c_str.split(';');
        let c_obj = {};

        c_arr.forEach((item) => {
            let item_arr = item.split('=');
            if (typeof item_arr[1] !== 'undefined') {
                c_obj[item_arr[0].trim()] = item_arr[1].trim();
            }
        })

        return c_obj;
    },

    set cookie(option) {
        // console.log(option);
        if (typeof option !== 'object' || !option.key) return;
        let opt = {
            key:   '',
            value: '',
            days:  7,
        }
        Object.assign(opt, option);
        let expires = new Date();
        expires.setTime(expires.getTime() + opt.days * 24 * 60 * 60 * 1000)
        document.cookie = `${opt.key}=${JSON.stringify(opt.value)};expires=${expires.toGMTString()}`
    },

    userLogin: function (data) {
        Cookies.set('userLoginStatus', true)
        Cookies.set('userName', data.user.name)
        Cookies.set('userId', data.user.uid)
        Cookies.set('currency', data.currency)
        Cookies.set('countryCode', data.location.CountryCode)
        Cookies.set('lang', data.lang)
    },

    userLogout: function () {
        Cookies.set('userLoginStatus', false)
        Cookies.set('userName', '')
        Cookies.set('uid', '')
    },

    getUserLoginStatus: function () {
        return Cookies.get('userLoginStatus') === 'true';
    },

    getUserName: function () {
        return Cookies.get('userName');
    },

    getUserId: function () {
        return Cookies.get('userId');
    },

    getLang: function () {
        return Cookies.get('lang');
    },

    getCountryCodeg: function () {
        return Cookies.get('countryCode');
    },

//loading mask
    loadingMask: function () {
        $('#loadingModal').modal('show');
    },

    //Countdown in top banner.
    countDown: function (id) {
        var $box = $(id);
        var sys_second = Number($box.data('deadline'));
        if (sys_second > 0) {
            var timer = setInterval(function () {
                if (sys_second > 1) {
                    sys_second--;
                    var hour = Math.floor(sys_second / 3600);
                    var minute = Math.floor((sys_second / 60) % 60);
                    var second = Math.floor(sys_second % 60);
                    hour = hour < 10 ? "0" + hour : hour;
                    minute = minute < 10 ? "0" + minute : minute;
                    second = second < 10 ? "0" + second : second;

                    $box.find('.time').html(hour + ':' + minute + ':' + second);
                } else {
                    $box.addClass('hide');
                    clearInterval(timer);
                }
            }, 1000);
        }
    },

    errorInput: function ($e) {
        $e.addClass('bd-red');
    },

    rightInput: function ($e) {
        $e.removeClass('bd-red');
    },

    setCartNumber: function (number) {
        if (number > 0) {
            $('.btn-cart').html(' <span class="count">' + number + '</span>');
            // $('#top-cart-number').text(number);
        } else {
            $('.btn-cart').html('');
        }
        var unit = number > 1 ? 'items' : 'item';
        $('.p-cart .item-num').text(number + ' ' + unit);
    },

    changeCurrency: function () {
        $('.js-currency-slc').on('change', function () {
            shopJS.common.ajaxChangeCurrency($(this).closest('select').val());
        });
    },
    ajaxChangeCurrency(target) {
        $u.http.post('/api/currency/change', {
            currency: target
        }).then(res => {
            if (res.data.code == 200) {
                window.location.reload();
            }
        }).catch()
    },

    alert: function (message) {
        var $alert = $('#alert');
        $alert.find('.modal-title').html(message);
        $alert.modal('show');
    },

    confirmModal: function (option, callback) {
        const $modal = $('#confirm');
        const $okBtn = $modal.find('.ok');
        let opt = Object.assign({
            text:       'Are you sure?',
            okText:     'Yes',
            cancelText: 'Cancel',
        }, option || {});
        let flag = false;

        $modal.find('.modal-title').text(opt.text);
        $modal.find('.cancel').text(opt.cancelText);

        $okBtn.text(opt.okText).one('click', function () {
            flag = true;
            $modal.modal('hide');
        });

        $modal.one('hide.bs.modal', function () {
            $okBtn.off('click');
            if (flag && callback) {
                callback();
            }
        });

        $modal.modal('show');

    },

    success: function (message, time = 10000, callback) {
        var $success = $('#successModal');
        $success.find('p').html(message);
        $success.modal('show');

        $(document).one('click', function () {
            $success.modal('hide');
        });

        let timer = setTimeout(function () {
            $success.modal('hide');
        }, time);

        $success.on('hide.bs.modal', function () {
            if (timer) clearTimeout(timer);
            if (callback && typeof callback === 'function') {
                callback();
            }
        })
    },

    toastModal(option) {
        const $modal = $('#toastModal');
        const $iconImg = $('#toastModal .icon');
        const $textBox = $('#toastModal .text');
        let opt = Object.assign({
            delay:        3000,
            id:           'default',
            icon:         '',
            text:         '',
            html:         '',
            clickToClose: true,
            callback:     null
        }, option)
        // console.log('toast modal exec.')
        let toastID = `toast_${opt.id}`;
        window.toast = window['toast'] || {};
        if (window.toast[toastID]) {
            updateContent(opt);
        } else {
            window.toast[toastID] = {
                init() {
                    $modal.modal('show');
                    window.toast[toastID]['timer'] = setTimeout(() => {
                        $modal.modal('hide');
                        $iconImg.attr('src', '');
                        $textBox.text('');
                        window.toast[toastID] = null;
                    }, opt.delay)

                    // 监听弹窗关闭
                    $modal.on('hide.bs.modal', () => {
                        clearTimeout(window.toast[toastID]['timer']);
                        if (typeof opt.callback === 'function') {
                            opt.callback();
                        }
                    })
                },
                timer: null
            };

            updateContent(opt);
            window.toast[toastID].init();
        }

        function updateContent(opt) {
            if (opt.icon) {
                $iconImg.attr('src', opt.icon).removeClass('hide');
            } else {
                $iconImg.addClass('hide');
            }

            if (opt.html) {
                $textBox.html(opt.html).removeClass('hide');
            } else if (opt.text) {
                $textBox.text(opt.text).removeClass('hide');
            } else {
                $textBox.addClass('hide');
            }
        }

    },

    /**
     *
     * @param [action] string;   //show: 显示并++;hide:--后隐藏;hidden:强制隐藏
     * @param [delay] number;    //自动关闭时间。
     * */
    loadingModal(action, delay) {
        const $modal = $('#loadingModal');
        if (!['show', 'hide', 'hidden'].includes(action)) {
            return
        }
        if (delay) {
            $modal.modal('show');
            setTimeout(() => {
                $modal.modal('hide');
            }, delay)
            return;
        }

        window.loading = window.loading || {
            count: 0
        }
        // console.log('loadingmodal.---------',action,window.loading.count);
        // console.log(window.loading);
        switch (action) {
            case 'show':
                if (window.loading.count++ <= 0) {
                    $modal.modal('show');
                }
                break;
            case 'hide':
                if (window.loading.count-- <= 1) {
                    $modal.modal('hide');
                }
                break;
            case 'hidden':
                window.loading.count = 0;
                $modal.modal('hide');
                break;
            default:
                break;
        }
    },

    share: function () {
        const $share = $('.js-share');
        let shareUrl = encodeURIComponent($share.data('shareurl'));
        let shareTitle = encodeURIComponent($share.data('sharetitle'));
        let shareImg = ''

        const $shareToMessage = $('.js-share-message');
        if ($shareToMessage.length) {
            $shareToMessage.on('click', function (e) {
                e.preventDefault();
                let link = "sms:?body=".concat(shareTitle, " ").concat(shareUrl)

                if (/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)) {
                    link = "sms:&body=".concat(shareTitle, " ").concat(shareUrl)
                }

                window.open(link, this.window);
            });
        }

        const $facebook = $('.js-share-facebook');
        if ($facebook.length) {
            $facebook.on('click', function (e) {
                e.preventDefault();
                shareImg = getCurImgSrc();
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareUrl + '&picture=' + shareImg, this.window);
            });
        }

        const $twitter = $('.js-share-twitter');
        if ($twitter.length) {
            $twitter.on('click', function (e) {
                e.preventDefault();
                let link = "https://twitter.com/share?text=".concat(shareTitle, "%26url=").concat(shareUrl)
                window.open(link, this.window);
            });
        }

        const $whatsapp = $('.js-share-whatsapp');
        if ($whatsapp.length) {
            $whatsapp.on('click', function (e) {
                e.preventDefault();
                window.open('https://wa.me/?text=' + shareTitle + ':%20' + shareUrl, this.window);
            });
        }

        const $pinterest = $('.js-share-pinterest');
        if ($pinterest.length) {
            $pinterest.on('click', function (e) {
                e.preventDefault();
                shareImg = getCurImgSrc();
                window.open('https://www.pinterest.com/pin/create/button/?url=' + shareUrl + '&media=' + shareImg + '&description=' + shareTitle, this.window);
            });
        }

        function getCurImgSrc() {
            const $prodCurImg = $('#prod-slider .swiper-slide-active img');
            const $share = $('.js-share');

            if ($prodCurImg.length > 0) {
                return $prodCurImg.attr('src');
            } else {
                return encodeURIComponent($share.data('shareimg'));
            }
        }
    },


    goBack: function () {
        const $btnBack = $('.btn-go-back');
        if (!$('body').hasClass('p-idx')) {
            $btnBack.css({
                visibility: 'visible'
            }).on('click', function () {
                let ref = document.referrer;
                let host = location.host;
                if (ref.indexOf(host) < 0) location.href = location.protocol + '//' + host;
                else history.back();
            })
        } else {
            $btnBack.hide(0);
        }
    },


    modalRouter: function () {
        var $modals = $('.modal');

        function ModalRouter($modals) {
            this.init();
            this.openModals = [];
            this.$modals = $modals;
        }

        ModalRouter.prototype.init = function () {
            /*todo: remove history state*/
            let url = window.location.href;
            let newUrl = url.replace(/#modalOpen\d+/ig, '');
            history.replaceState('', '', newUrl);
        };

        ModalRouter.prototype.open = function ($modal) {
            this.openModals.push($modal);
            //console.log(this.openModals);
            history.pushState($modal.attr('id'), null, location.href + '#modalOpen' + this.openModals.length);
        };

        ModalRouter.prototype.close = function () {
            let url = location.href;
            //console.log(this.openModals);
            if (url.indexOf('#modalOpen') >= 0) {
                history.go(-1);
                this.openModals.pop();
            }
        };

        const $modalRoute = new ModalRouter($modals);

        $modalRoute.$modals.on('show.bs.modal', function () {
            let $this = $(this);
            let isNonUrl = !!$this.data('nonurl');
            if (!isNonUrl) {
                $modalRoute.open($this);
            }
        });

        $modalRoute.$modals.on('hide.bs.modal', function () {
            let $this = $(this);
            let isNonUrl = !!$this.data('nonurl');
            if (!isNonUrl) {
                $modalRoute.close();
            }
        });

        $(window).on('popstate', function () {
            //console.log(history.state);
            //console.log($modalRoute.openModals);
            if ($modalRoute.openModals.length >= 1) {
                $modalRoute.openModals.pop().modal('hide');
            }
        });
    },


    getSnFromUrl:       function () {
        var url = window.location.href;
        var re = /-p(.*)\.(htm|html)/i;
        var found = url.match(re);
        if (!!found && found.length > 0) {
            return found[1];
        }
        return null;
    },
    getCurrentCurrency: function () {
        var currency = $(".js-currency-box  span").html();
        currency = currency ? currency : "USD";
        return currency;
    },

    getUrlParams: function () {
        var params = {};
        var paramsStr = location.search;
        var paramsArray = paramsStr.split('?');
        if (paramsArray.length > 1) {
            paramsArray[1].split('&').map(function (item) {
                var itemArray = item.split('=');
                params[itemArray[0]] = itemArray[1];
            })
        }
        return params
    },

    /**
     *
     * scheme: <div class="js-auto-copy" data-text="couponcode">copy</div>
     *
     */
    autoCopy: function (el, parentEl) {
        $(el).on('click', function (event) {
            const $this = $(this);
            let text = $this.data('text');
            let range, selection, mark, fn;
            if ($(this).hasClass('share-link')) {
                shopJS.report.monitor({label: 'coupon-refer page', action: 'copy button'});
            }

            fn = removeRange();

            try {
                range = document.createRange();
                selection = document.getSelection();
                mark = document.createElement('mark');
                mark.textContent = text;
                mark.style.whiteSpace = "pre";
                document.body.appendChild(mark);
                range.selectNode(mark);
                selection.addRange(range);
                if (document.execCommand("Copy")) {
                    alert("Copied successfully.");
                }
            } catch (i) {
                try {
                    window.clipboardData.setData("text", text)
                } catch (e) {
                    window.prompt('Copy to clipboard: Ctrl+C, Enter', text)
                }
            } finally {
                selection && selection.removeAllRanges();
                mark && document.body.removeChild(mark);
                fn()
            }

            function removeRange() {
                let t = document.getSelection();
                if (!t.rangeCount) {
                    return function () {
                    }
                }
                let e = document.activeElement;
                let i = []
                for (let n = 0; n < t.rangeCount; n++) {
                    i.push(t.getRangeAt(n));
                }
                switch (e.tagName.toUpperCase()) {
                    case "INPUT":
                    case "TEXTAREA":
                        e.blur();
                        break;
                    default:
                        e = null;
                }
                t.removeAllRanges();
                return function () {
                    if (t.type === "Caret") {
                        t.removeAllRanges()
                    }
                    if (!t.rangeCount) {
                        i.forEach((function (e) {
                                t.addRange(e)
                            }
                        ))
                    }
                    if (e) {
                        e.focus();
                    }
                }
            }
        });
    },

    createGoogleTranslate() {
        let targetLanguage = ['en', 'fr', 'es', 'pt', 'ja', 'sv', 'de', 'ru', 'ar', 'ko', 'zh-TW', 'zh']
        window.googleTranslateElementInit || (window.googleTranslateElementInit = function () {
            new google.translate.TranslateElement({
                pageLanguage:           'en',
                includedLanguages:      targetLanguage.join(','),
                autoDisplay:            false,
                disableAutoTranslation: false,
                multilanguagePage:      true,
                layout:                 google.translate.TranslateElement.InlineLayout.HORIZONTAL,
            }, 'google_translate_element');

            $('#goog-gt-tt img,#google_translate_element img').each(function () {
                this.src = '';
            });
        });

        function createGoogleTranslateElement() {
            // var translate = (("https:" == document.location.protocol) ? "https://" : "http://");
            var gts = document.createElement("script");
            gts.type = "text/javascript";
            gts.async = true;
            gts.src = "https:" + "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(gts, s);
        }

        const sysLang = navigator.language ? navigator.language : '';
        const urlParams = $u.getUrlParams();
        let urlParamLang = 'fromlang' in urlParams ? urlParams['fromlang'] : 'language' in urlParams ? urlParams['language'] : '';
        let customLang = Cookies.get('googtrans');
        let targetLang, delay = 30;
        let domain = location.hostname.split('.').splice(-2, 2).join('.');

        // console.log(sysLang, urlParamLang, customLang);
        if (sysLang.indexOf('en') >= 0) return;
        if (!customLang) {
            if (urlParamLang) {
                targetLang = urlParamLang;
            } else if (sysLang.indexOf('ru') >= 0) {
                targetLang = 'ru';
                // shopJS.common.ajaxChangeCurrency('RUB');
            }

            if (targetLang) {
                document.cookie = `googtrans=/en/${targetLang}`;
                document.cookie = `googtrans=/en/${targetLang};domain=${domain}`;
                delay = 1
            }

        } else {
            if (customLang.slice(-2) === 'en') {
                document.cookie = `googtrans=;`;
                document.cookie = `googtrans=;domain=${domain};`;
            } else {
                document.cookie = `googtrans=${customLang}`;
                document.cookie = `googtrans=${customLang};domain=${domain}`;
                delay = 1
            }
        }

        $('body').addClass('custom-translate');

        setTimeout(() => {
            createGoogleTranslateElement();
        }, delay * 1000) // defer
    },

    setGlobalMsg: function () {
        var message = Cookies.get('globalMessage');
        if (message && message.length > 3) {
            $('.global-message').text(message).removeClass('hide')
        } else {
            $('.global-message').addClass('hide')
        }
    },

    bindClickItemInList() {
        // console.log('bind link')
        $('.ga4-prod-item a').on('click', function () {
            let $item = $(this).parents('.ga4-prod-item');
            let $list = $item.parents('.ga4-prod-list');
            // console.log('click link')

            if ($list.length < 1) {
                return
            }
            let data = {}

            data.termID = `${$list.data('term-id')}_${$list.data('request-id')}`;
            data.termName = $list.data('term-name');
            data.products = [{
                name:  $item.data('name'),
                id:    $item.data('sn'),
                index: $item.data('index-in-term'),
            }]

            shopJS.report.selectItem(data);
        })
    },

    // type: 'alert' | 'string'
    responseErrorOutput(err, type = 'alert') {
        if (!err.response) return false;
        let res = err.response;
        let errorStr = '';
        let data = res.data;

        if (typeof data === 'object') {
            for (let i in data) {
                if (type === 'alert') {
                    alert(data[i])
                }

                errorStr += data[i] + ';'
            }
        }
        return errorStr
    },

    initImage() {
        if ($d.config.isSupportWebp) {
            return
        }
        const $images = $('img');
        $images.each(function () {
            const $img = $(this);
            let src = $img.attr('src');
            if (src.indexOf('format=webp') >= 0) {
                $img.attr('src', src.replace('format=webp', ''));
            }
        })
    },

    getClientInfo() {
        let ua = navigator.userAgent;
        let tem;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    },

    reportMonitor($doms) {
        /* 传入需要监控的dom集合，
        即可在页面加载后调用，
        又可在异步数据到达后调用。
         */

        /* 非jQuery对象不做处理 */
        if (!($doms instanceof jQuery) || $doms.length < 1) return;
        const $showDoms = $doms.filter('[data-monitor*="show"]');
        const $clickDoms = $doms.filter('[data-monitor*="click"]');

        $showDoms.each(function () {
            const $this = $(this);
            let data = {
                category: $this.data('m-c'),
                label:    $this.data('m-l'),
                action:   'show',
                value:    $this.data('m-v') || ''
            }

            shopJS.report.monitor(data);
        })

        $clickDoms.on('click', function () {
            const $this = $(this);
            let data = {
                category: $this.data('m-c'),
                label:    $this.data('m-l'),
                action:   'click',
                value:    $this.data('m-v') || ''
            }

            shopJS.report.monitor(data)

        })
    },

    bindDrag() {
        let dragEls = $('.drag-target');

        dragEls.each(function () {
            const dragEl = new $u.Drag(this);
            dragEl.bindEvent();
        })
    },

    bindInputClearBtn() {
        const $ipts = $("input[clearable]");
        const btnDelWidth = 20;
        /* add button */
        $ipts.each(function () {
            let $ipt = $(this);
            let rect = this.getBoundingClientRect();
            let btnDel = $('<span></span>', {
                class: 'btn_clear hide'
            }).css({
                display:        'block',
                position:       'absolute',
                height:         rect.height,
                width:          btnDelWidth + 'px',
                background:     'url(/build/images/global/icon_delete.png) center center no-repeat',
                backgroundSize: 'contain',
            })
            $ipt.after(btnDel).parent().css({position: 'relative'});
        })

        /* bind button click */
        $ipts.siblings('.btn_clear').on('click', function () {
            $(this).addClass('hide').siblings('input').val('').focus();
        })

        /* bind ipunt focus/input/change */
        $ipts.on('focus input change', function () {
            let $ipt = $(this);
            let $btnDel = $ipt.siblings('.btn_clear')
            if ($ipt.val() === '') {
                $btnDel.addClass('hide');
            } else {
                let rect = this.getBoundingClientRect();
                $btnDel.css({
                    height: rect.height,
                    top:    this.offsetTop + 'px',
                    left:   (this.offsetLeft + rect.width - btnDelWidth - 5) + 'px',
                }).removeClass('hide')
            }
        })
    },

    cookiesUsage(code) {
        // code='IE'
        const $modal = $('#CookiesUsageModal');

        /* https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Country_codes */
        let isShowCountryCode = [
            'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL',
            'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', '|', 'IS', 'LI', 'NO', 'CH'
        ] //country code
        const cookieKey = 'useCookieAccept'; //1:Accept all,2:Accept necessary
        const cookieDays = 500;
        let curCookieVal = Cookies.get(cookieKey);

        if (!isShowCountryCode.includes(code.toUpperCase())) {
            $modal.remove();
            window.loadTagManager()
            return
        } else {
            gtag('consent', 'default', {
                'ad_user_data':       'denied',
                'ad_personalization': 'denied',
                'ad_storage':         'denied',
                'analytics_storage':  'denied',
                'wait_for_update':    500,
            });
        }

        if (curCookieVal >= 1) {
            $modal.remove();
            shopJS.common.loadTagManager(curCookieVal != 2)
        } else {
            $modal.modal('show')
            $modal.find('.btn_allowAll').on('click', () => {
                $modal.modal('hide')

                Cookies.set(cookieKey, 1, {
                    expires: cookieDays,
                })
                shopJS.common.loadTagManager(true)
            })

            $modal.find('.btn_acceptNecessary').on('click', () => {
                $modal.modal('hide')

                Cookies.set(cookieKey, 2, {
                    expires: cookieDays,
                })

                shopJS.common.loadTagManager(false)
            })
        }
    },

    loadTagManager(isAccept) {
        if (isAccept) {
            window.gtag('consent', 'update', {
                ad_user_data:       'granted',
                ad_personalization: 'granted',
                ad_storage:         'granted',
                analytics_storage:  'granted'
            });
        }
        window.loadTagManager()
    }

};
