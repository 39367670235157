
shopJS.size={
    sizeToggle:function () {
        shopJS.size.sizeChartVideoInit();
        $(".tab li").on('click',function(){
            let curIndex =  $(this).index();
            $(this).addClass("cur").siblings('li').removeClass("cur");
            $(".size-box").each(function(){
                $(this).find('.size-list').eq(curIndex).show().siblings('.size-list').hide();
            })
        });
    },

    sizeChartVideoInit(){
        var $iframe=$('.iframe-wrap iframe')
        if($iframe.is(':visible')){
            shopJS.prod.loadingIframe($iframe)
        }
    }


};
