shopJS.googleAds = {
    googleAds: function (type, id, value) {
        if (typeof google_tag_params == 'undefined') {
            window.google_tag_params = {};
        }

        if (typeof type != 'undefined' && type) {
            google_tag_params.m_ecomm_pagetype = type;
        } else {
            google_tag_params.m_ecomm_pagetype = 'other';
        }

        if (typeof id != 'undefined' && id) {
            google_tag_params.m_ecomm_prodid = id;
        }

        if (typeof value != 'undefined' && value) {
            google_tag_params.m_ecomm_totalvalue = value;
        }
    }
};