
var $facebook_pixel = $('.facebook_pixel_flag');
var facebook_pixel_flag = false;

if($facebook_pixel.length>0) facebook_pixel_flag = true;

$facebook_pixel = null

shopJS.facebook = {

    status: facebook_pixel_flag,

    // 查看页面，如果是商品页面，则传入 product sn
    viewContent: function (val, currency, content_ids, content_type) {
        fbq('track', 'ViewContent', {
            value: val,
            currency: currency,
            content_ids: content_ids,
            content_type: content_type
        });
    },

    // 搜索关键词
    search: function (keyword) {
        fbq('track', 'Search', {
            search_string: keyword
        })
    },

    // 添加收藏
    addToWish: function (sn) {
        fbq('track', 'AddToWishlist', {
            content_ids: [sn]
        });
    },

    // 完成注册
    Registed: function () {
        fbq('track', 'CompleteRegistration');
    },

    // 注册
    register: function () {
        fbq('track', 'Lead');
    },

    // 增加单个商品 to cart
    addToCart: function (val, currency, content_ids, content_type) {
        fbq('track', 'AddToCart', {
            value: val,
            currency: currency,
            content_ids: content_ids,
            content_type: content_type
        })
    },

    // 到填写地址页面
    initCheckout: function () {
        fbq('track', 'InitiateCheckout');
    },

    // 填写支付信息
    addPaymentInfo: function () {
        fbq('track', 'AddPaymentInfo');
    },

    // 完成购买
    purchase: function (value, currency, contentIds=[], contentType='product') {
        fbq('track', 'Purchase', {
            value: value,
            currency: currency,
            content_ids: contentIds,
            content_type: contentType
        });
    }
};


