shopJS.paySuccess = {
    init: function () {
        shopJS.paySuccess.success();
        shopJS.googleAds.googleAds('purchase', null, shopJS.paySuccess.getPayAmount())
    }
    ,getPayAmount: function () {
        return $('.js_order_amount').text();
    },
    success: function () {
        let currency = "USD";
        let value = $(".js_order_amount").text();
        let orderNumber = $('.js_order_number').text();
        let deliveryEmail = $('.js_delivery_email').text();

        /* report  仅从符合条件的支付页过来才上报*/
        if(shopJS.payment.checkPaymentFrom()) {
            let data=JSON.parse($('#ga-payment-success').val());

            // facebook pixel
            if (shopJS.facebook.status) {
                shopJS.facebook.purchase(value,currency,data.products.map(i=>i.id),'product');
            }

            // console.log(data);
            //如果没有设置阻拦过滤或者在推送次数限制之内则运行:
            if(typeof(addUrlToGA) !== 'function' || addUrlToGA(null,'google_conversion',1) > 0){
                shopJS.salesTracker.paymentSucceededSalesTracker();
                shopJS.report.purchase({
                    action: data.action,
                    products: data.products,
                    value: value,
                    orderNumber: orderNumber,
                    currency: currency,
                    email: deliveryEmail
                });
            }
            /* report end */
        }


        $('.js-detail-copy').on('click', function () {
            var $target = $('#save-order-info');
            var strInfo = '          ORDER NUMBER \r\n ' +
                '        ' + String($target.data('order-number')).toUpperCase() + ' \r\n' +
                '-----------------------------------------------------\r\n' +
                'ORDER DATE         \t' + $target.data('order-date') + ' \r\n' +
                'REGISTERED EMAIL   \t' + $target.data('order-email') + ' \r\n' +
                'CUSTOMER SERVICE   \tsupport@ucenterdress.com \r\n' +
                '-----------------------------------------------------\r\n' +
                'TOTAL AMOUNT       \t' + $target.data('order-total') + ' \r\n' +
                '\r\n' +
                'By ' + String(window.location.hostname).toUpperCase();
            console.log(strInfo);
            $('#save-order-info').val(strInfo);
            $target[0].select();
            try {
                if (document.execCommand("Copy")) {
                    alert("Copy success. You can now paste (Ctrl + v) in notepad and save.");
                    return
                }
            } catch (i) {
                //console.log(i);
            }
            $(this).addClass('disabled').css({color: '#aaa'});
            alert("Your browser does not support this function, please select and copy, and then save it.")
        });
    },
};