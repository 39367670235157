


shopJS.sign={
    init:function(){
        shopJS.sign.signToggle();
        shopJS.sign.signin();
        shopJS.sign.signup();
        $('.sign-box').on('keypress','input',function(){
            $(this).parent().removeClass('is-error');
        });
    }

    ,signToggle:function () {

        $(".sign-tab .top").click(function(){
            $(".sign-tab .top").eq($(this).index()).addClass("cur").siblings(".sign-tab div").removeClass("cur");
            $(".sign-box .toggle").hide().eq($(this).index()).show();
        })
    }
    ,signin:function () {

        $('.sign-box').on('click','.signin-submit-btn', function(e){
            e.preventDefault();
            // shopJS.common.axSetup();

            var $signinForm = $(this).parents('form');
            var $email = $signinForm.find('input[name=email]');
            var $password = $signinForm.find('input[name=password]');
            var email = $email.val();
            var password = $password.val();

            var error = 0;

            if(!$email.easyVali('email')){
                $email.parent().addClass('is-error');
                $email.siblings('p').html('Please enter the correct Email Address');
                error += 1;
            }

            if(!$email.easyVali('required')){
                $email.parent().addClass('is-error');
                $email.siblings('p').html('Please enter your Email Address');
                error += 1;

            }

            if(!$password.easyVali('string',{minLen:6,maxLen:30})){
                $password.parent().addClass('is-error');
                $password.siblings('p').html('Password length should not be less than 6 and not more than 30');
                error += 1;

            }

            if(!$password.easyVali('required')){
                $password.parent().addClass('is-error');
                $password.siblings('p').html('Please enter your Password');
                error += 1;

            }

            if(error > 0){
                return ;
            }

            $u.http.post('/api/user/login',{'email': email, 'password': password}).then(res=>{
                let result = res.data;
                if(result.code == 200){
                    window.location = document.referrer || '/';
                }else{
                    $signinForm.find('input[name=password]').parent().addClass('is-error');
                    $signinForm.find('input[name=password]').siblings('p').html(result.data.message);
                }
            }).catch()
        });
    }
    ,signup:function () {
        $('.toggle').on('click','.signup-submit-btn', function(e){
            e.preventDefault();
            // shopJS.common.axSetup();

            var $signupForm = $(this).parents('form');
            var $email = $signupForm.find('input[name=email]');
            var $password = $signupForm.find('input[name=password]');
            var $password_confirmation = $signupForm.find('input[name=password_confirmation]');
            var email = $email.val();
            var password = $password.val();
            var password_confirmation = $password_confirmation.val();

            var error = 0;

            if(!$email.easyVali('email')){
                $email.parent().addClass('is-error');
                $email.siblings('p').html('Please enter the correct Email Address');
                error += 1;
            }

            if(!$email.easyVali('required')){
                $email.parent().addClass('is-error');
                $email.siblings('p').html('Please enter your Email Address');
                error += 1;
            }

            if(!$password.easyVali('string',{minLen:6,maxLen:30})){
                $password.parent().addClass('is-error');
                $password.siblings('p').html('Password length should not be less than 6 and not more than 30');
                error += 1;
            }

            if(!$password.easyVali('required')){
                $password.parent().addClass('is-error');
                $password.siblings('p').html('Please enter your Password');
                error += 1;
            }

            if(password != password_confirmation){
                $password_confirmation.parent().addClass('is-error');
                $password_confirmation.siblings('p').html('Two password input does not match');
                error += 1;
            }

            if(!$password_confirmation.easyVali('required')){
                $password_confirmation.parent().addClass('is-error');
                $password_confirmation.siblings('p').html('Please enter your Password twice');
                error += 1;
            }

            if(error > 0){
                return
            }

            // facebook pixel
            if (shopJS.facebook.status) {
                shopJS.facebook.register();
            }
            // facebook pixel

            $u.http.post('/api/user/register',{
                'name' : email,
                'email' : email,
                'password' : password,
                'password_confirmation' : password_confirmation
            }).then(res=>{
                let result = res.data;
                if(result.code === 200){
                    if(result.data.redirectTo != undefined){
                        window.location.href = result.data.redirectTo;
                    } else {
                        window.location.href = '/home';
                    }
                } else {
                    $signupForm.find('input[name=password_confirmation]').parent().addClass('is-error');
                    $signupForm.find('input[name=password_confirmation]').siblings('p').html(result.data.message);
                }
            }).catch(err=>{
                // console.log(err.response);
                let res = err.response;
                let result = res.request;
                $signupForm.find('input[name=password_confirmation]').parent().addClass('is-error');
                $signupForm.find('input[name=password_confirmation]').siblings('p').html(
                    result.responseText.substring(result.responseText.indexOf('["') + '["'.length,result.responseText.indexOf('"]')));
            })
        });
    }

};
