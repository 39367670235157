// vip
shopJS.vip = {
    init: function () {
        const staticCategory = new shopStatic.category();

        if($('.p-weblebrity').length < 1) {
            shopJS.vip.getAdvertiseSlider();
            shopJS.vip.navInit();
        }

        staticCategory.syncProductInfo()
    },

    sliderInit: function () {
        const $slide = $('.vip-slide li.swiper-slide');
        let options = {
            autoplay: true,
            delay: 5000,
            watchOverflow: true,
            pagination: {
                el: '.vip-swiper-pagination'
            },
        };

        if($slide.length>1){
            options.loop = true;
            delete options.watchOverflow;
        }



        new Swiper('.vip-slide',options);
    },

    navInit(){
        const $vipNnav= $('.vip-nav');
        const $navItem= $('.vip-nav .item');
        const $box = $('.section .prod-box');

        if($navItem.length < 2) return

        let boxCount= $box.length;
        let boxTopArr=getBoxScroll();
        let isClickNav = false;

        setTimeout(()=>{
           boxTopArr = getBoxScroll();
            $navItem.on('click', function () {
                let $this=$(this);
                let index = $this.data('nav');
                let $targetBox,targetScrollTop;

                $this.addClass('cur').siblings('.item').removeClass('cur');

                for(let i=0;i<boxCount;i++) {
                    if($box.eq(i).data('box') === index) {
                        $targetBox = $box.eq(i);
                        break;
                    }
                }
                if($targetBox.length<1) return;

                targetScrollTop = $targetBox.offset().top;

                // console.log($targetBox);
                boxTopArr = getBoxScroll();

                isClickNav = true;
                $('html,body').animate({
                    scrollTop: targetScrollTop-85
                }, 300,function(){
                    setTimeout(()=>{
                        isClickNav=false;
                    },200)
                });
            });

            $(window).on('scroll', $u.throttle(this,handlerScroll,100));
        });
        // console.log(getBoxScroll());


        function handlerScroll() {
            let scrollTop = $(window).scrollTop();
            let $topBanner = $('.top-banner-wrap');

            if($topBanner.is(':visible')) {
                $vipNnav.css('top','38px');
            }

            if(isClickNav) return

            if(scrollTop<boxTopArr[0].top){
                setCurNavItem(0);
                return;
            }
            if(scrollTop>boxTopArr[boxCount-1].top){
                setCurNavItem(boxCount-1);
                return;
            }

            for(let i=0;i<boxCount;i++) {
                if(scrollTop>boxTopArr[i].top && i+1<boxCount && scrollTop<boxTopArr[i+1].top) {
                    setCurNavItem(i);
                    break;
                }
            }

        }

        function getBoxScroll(){
            return $box.map(function(){
                let $this=$(this);
                return {index: $this.data('box'),top:$this.offset().top}
            })
        }

        function setCurNavItem(index) {
            $navItem.eq(index).addClass('cur').siblings('.item').removeClass('cur');
        }
    },

    getAdvertiseSlider(){
        return $u.http.get('/api/activity/sliders')
                 .then(function(response){
                     var result = response.data;
                     if(result.code == 200 && result.data.sliders != undefined && result.data.sliders.length > 0){
                         var template = '';
                         $.each(result.data.sliders, function(index, slider){
                             var alt = slider.alt ? slider.alt : slider.title;
                             template += '<li class="swiper-slide">';
                             if(slider.url){
                                 template += '<a href="'+slider.url+'" title="'+slider.title+'" >';
                                 template += '<img src="'+slider.filename+'" alt="'+alt+'" title="'+slider.title+'" >';
                                 template += '</a>';
                             }else{
                                 template += '<img src="'+slider.filename+'" alt="'+alt+'" title="'+slider.title+'" >';
                             }
                             template += '</li>';
                         });
                         $('.vip-slide ul').html(template);
                         shopJS.vip.sliderInit();
                     }
                 })
                 .catch(function(error){
                     console.log(error);
                 });
    },

};