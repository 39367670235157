
shopJS.ga4 = {
    init() { },

    callbackTimer:[],
    callbackDelayTime: 1000, /* 回调函数若未在1秒内执行，则自动执行*/

    /**
     * @param  { Function | undefined | null } callback
     * @return { Function }
     * */
    buildCallbackClosure(callback) {
        if(typeof callback !== 'function') {
            return function(){}
        }

        let cbID=shopJS.ga4.callbackTimer.length;
        let timer = setTimeout(()=>{
            shopJS.ga4.execCallback(callback,cbID)
        }, shopJS.ga4.callbackDelayTime)
        shopJS.ga4.callbackTimer.push(timer);
        return function(){
            shopJS.ga4.execCallback(callback,cbID)
        }
    },

    /**
     * @param  { Function | undefined } callback
     * @param  { Number }  cbID  - callback id & timer index.
     * @return { void }
     * */
    execCallback(callback,cbID) {
        if (shopJS.ga4.callbackTimer[cbID] >= 0) {
            clearTimeout(shopJS.ga4.callbackTimer[cbID]);
            shopJS.ga4.callbackTimer[cbID] = -1;
            callback();
        }
    },

    convertItems(products){
        let items = [];
        products.forEach((i)=>{
            let item = {};
            if(typeof i.name !== "undefined") {
                item['item_name'] = i.name;
            }
            if(typeof i.id !== "undefined") {
                item['id'] = i.id;
                item['item_id'] = i.id;
            }
            if(typeof i.index !== "undefined") {
                item['index'] = i.index;
            }
            if(typeof i.price !== "undefined") {
                item['price'] = i.price;
            }
            if(typeof i.quantity !== "undefined") {
                item['quantity'] = i.quantity;
            }else {
                item['quantity'] = 1;
            }

            item['google_business_vertical'] = 'retail';

            items.push(item)
        })

        return items
    },

    /*
    * select item.
    *
    * */
    selectItem(data){
        let items = shopJS.ga4.convertItems(data.products);
        window.dataLayer.push({
            'event':            'ga4_select_item',
            'item_list_id':     data.termID,
            'item_list_name':   data.termName,
            'items':            items
        });
    },

    /*
    * add to wish list.
    * */
    addToWishList(products, value='0',currency='USD'){
        let items = shopJS.ga4.convertItems(products);
        window.dataLayer.push({
            'event':            'ga4_add_to_wishlist',
            'currency':         currency,
            'value':            value,
            'items':            items
        });
    },

    detail(products=[]){
        if(products.length<=0) return;
        let items = shopJS.ga4.convertItems(products);
        window.dataLayer.push({
            'event':        'ga4_view_item',
            'currency':     'USD',
            'value':        items[0].price,
            'items':        items
        });
    },

    addToCart(products=[]){
        if(products.length<=0) return;
        let items = shopJS.ga4.convertItems(products);
        window.dataLayer.push({
            'event':        'ga4_add_to_cart',
            'currency':     'USD',
            'value':        items[0].price,
            'items':        items
        });
    },

    purchase(products=[],value = 0.13, transaction_id = '',currency = 'USD'){
        let items = shopJS.ga4.convertItems(products);
        window.dataLayer.push({
            'event':            'ga4_purchase',
            'currency':         currency,
            'transaction_id':   transaction_id,
            'value':            value,
            'items':            items
        });
    },
};