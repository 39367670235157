// // 2019 black friday js.
// shopJS.bf2019 = {
//     init: function () {
//          shopJS.bf2019.checkInStatus();
//          shopJS.bf2019.toggleRules();
//
//         $('#CheckInModal2019 .close').on('click', function () {
//             Cookies.set('popCheckInModal', 'no');
//         })
//     },
//     checkInStatus: function () {
//         const $btnCheckIn = $('.btn-check-in-2019');
//
//         $.ajax({
//             type: 'post',
//             url: '/api/activity/sign-in/check',
//             success: function (res) {
//                 if (res.code === 200) {
//                     $btnCheckIn.removeClass('hide');
//                     shopJS.bf2019.getCheckInInfo(function () {
//                         if (!$('#CheckInModal2019 .btn-check-in').hasClass('disable')
//                             && Cookies.get('popCheckInModal') !== 'no') {
//                             // $('#CheckInModal2019').modal('show');
//                         }
//                     });
//                 } else {
//                     $btnCheckIn.remove();
//                 }
//             },
//             error: function (err) {
//                 console.log(err);
//                 $btnCheckIn.remove();
//             }
//         })
//     },
//     getCheckInInfo: function (callback) {
//         const $modal = $('#CheckInModal2019');
//         const $items = $modal.find('.day-list .item');
//
//         $.ajax({
//             type: 'post',
//             url: '/api/activity/sign-in/info',
//             success: function (res) {
//                 // console.log(res)
//                 if (res.code === 200) {
//                     store.auth = true;
//                     $items.slice(0, res.data.day).addClass('checked');
//                     $modal.find('.points-number').text(res.data.earned);
//                     if (res.data.isUserSignInToday || res.data.isOverLimit) {
//                         $modal.find('.btn-check-in').addClass('disable');
//                     }
//                     if (callback) {
//                         callback()
//                     }
//                 } else if (res.data.nextAction == 'login') {
//                     store.auth = false;
//                     if (Cookies.get('popCheckInModal') !== 'no') {
//                         // $('#CheckInModal2019').modal('show');
//                     }
//                     return
//                 }
//             },
//             error: function (err) {
//                 console.log(err)
//             }
//         })
//     },
//     checkInSubmit: function () {
//         $('#CheckInModal2019 .btn-check-in').on('click', function () {
//             if ($(this).hasClass('disable')) {
//                 return;
//             }
//
//             if(!store.auth) {
//                 $('#CheckInModal2019').modal('hide');
//                 window.location.href='/login?targetUrl='+window.location.href;
//                 return;
//             }
//
//             $.ajax({
//                 type: 'post',
//                 url: '/api/activity/sign-in/save',
//                 success: function (res) {
//                     console.log(res);
//                     shopJS.bf2019.getCheckInInfo();
//                     ga('send', {
//                         hitType: 'event',
//                         eventCategory: 'activity',
//                         eventAction: 'sign-in',
//                         eventLabel: 'black-friday'
//                     });
//                 },
//                 error: function (err) {
//                     console.log(err)
//
//                 }
//             })
//         })
//
//     },
//
//     clickSideBtn: function () {
//         $('.btn-check-in-2019').on('click', function (e) {
//             e.preventDefault();
//             $('#CheckInModal2019').modal('show');
//         });
//     },
//
//     toggleRules: function() {
//         const $btnShowRules = $('#CheckInModal2019 .rules-box .btn-rules');
//         const $btnHideRules = $('#CheckInModal2019 .rules-content .btn-rules-back');
//         const $checkInBody = $('#CheckInModal2019 .modal-content .inner-content');
//         const $RulesBody = $('#CheckInModal2019 .modal-content .rules-content');
//
//         $btnShowRules.on('click',function(){
//             $checkInBody.addClass('hide');
//             $RulesBody.removeClass('hide')
//         });
//
//         $btnHideRules.on('click',function(){
//             $RulesBody.addClass('hide')
//             $checkInBody.removeClass('hide');
//         });
//     },
//
//     initGetCouponModal: function() {
//         const $modal=$('#GetCouponModal2019');
//
//         if(Cookies.get('popGetCouponModal') !== 'no') {
//             $modal.modal('show');
//             Cookies.set('popGetCouponModal', 'no');
//         }
//
//         $modal.find('.code-body .btn-click').on('click',function(){
//             $modal.find('.code-body .btn-click').addClass('shake shake-constant');
//             setTimeout(function(){
//                 Cookies.set('globalMessage', '* Exclusive coupon code: '+$('#GetCouponModal2019 .code-number').text());
//                 shopJS.common.setGlobalMsg();
//                 $modal.find('.code-body .btn-click').addClass('hide').removeClass('shake shake-constant');
//                 $modal.find('.code-body .code').removeClass('hide');
//                 $modal.find('.success-tips').removeClass('hide');
//                 // $modal.find('.btn-copy').removeClass('hide');
//             },600)
//         });
//     },
//
//     initFlashSaleCountdown: function (el) {
//         const $clock = $(el);
//         const $hour_1 = $clock.find('.hour .s1');
//         const $hour_2 = $clock.find('.hour .s2');
//         const $minute_1 = $clock.find('.minute .s1');
//         const $minute_2 = $clock.find('.minute .s2');
//         const $second_1 = $clock.find('.second .s1');
//         const $second_2 = $clock.find('.second .s2');
//         let deadline = $clock.data('deadline');
//
//         if (!deadline || deadline < 3) {
//             return
//         }
//         const clock = new Countdown(deadline, {
//             everySecondFn: function (date) {
//                 let hourArr = date.hour.split('');
//                 let minuteArr = date.minute.split('');
//                 let secondArr = date.second.split('');
//                 $hour_1.text(hourArr[0]);
//                 $hour_2.text(hourArr[1]);
//                 $minute_1.text(minuteArr[0]);
//                 $minute_2.text(minuteArr[1]);
//                 $second_1.text(secondArr[0]);
//                 $second_2.text(secondArr[1]);
//             },
//             finalFn: function (date) {
//                 console.log('final')
//             }
//         });
//
//         clock.start()
//     },
//
// };