

shopJS.static={

    commonStatic(){
        let staticCommon = new shopStatic.common();
        staticCommon.getUser()
    },
    // indexStatic : function(){
    //     let staticIndex = new shopStatic.index();
    //     staticIndex.getAdvertiseSlider()
    // },
    categoryStatic (){
        let staticCategory = new shopStatic.category();
        staticCategory.syncProductInfo()
    },

    productStatic(){
        let staticProduct =  new shopStatic.product();
        staticProduct.getProductUserInfo()
        staticProduct.getFabricProduct()
        staticProduct.getMatchProduct()
    },

    baseStatic(){
        let staticCommon = new shopStatic.common();
        staticCommon.getCart()
        staticCommon.getAdBanners()
        staticCommon.getPosterModal()
        staticCommon.getHistory()
    }
};
