

shopJS.footer = {
    init() {
        shopJS.footer.toogleBlogList();
    },

    toogleBlogList() {
        const lists = $('footer .blog-list .item');

        lists.each(function(){
            const $this = $(this);

            $this.find('.title').on('click',function(){
                if($this.hasClass('open')){
                    $this.removeClass('open');
                }else {
                    $this.addClass('open').siblings('.item').removeClass('open');
                }
            })
        })
    }

};
