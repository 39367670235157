
shopJS.bing = {
    init() { },

     /* Add to cart.
    * */
    addToCart(products=[]){
        if(products.length<=0) return;
        let itemsId = products.map(i=>i.id);
        dataLayer.push({
            'event':        'bing_t_add_to_cart',
            'items_id':     itemsId,
            'value':        products[0].price,
        });
    },

    purchase(products=[],value = 0.13, transaction_id = ''){
        let itemsId = products.map(i=>i.id);
        window.dataLayer.push({
            'event':            'bing_t_purchase',
            'transaction_id':   transaction_id,
            'value':            value,
            'items_id':         itemsId
        });
    },
};