


shopJS.checkout = {
    init:function(){
        // shopJS.common.axSetup();
        shopJS.checkout.address();
        shopJS.checkout.shippingInit();
        shopJS.checkout.payment();
        shopJS.checkout.placeOrder();
        shopJS.checkout.pointConsume();
        shopJS.checkout.initCoupon();
        shopJS.cart.cartItemDetail();

        // add facebook pixel
        if(shopJS.facebook.status){
            shopJS.facebook.initCheckout();
        }
        // add facebook pixel
    },

    address : function(){
        shopJS.ckAddress.loadGoogleMapJs();

        /* init province, run once*/
        shopJS.ckAddress.ajaxCountryChange($('.js-country-list'),function(){
            const $pid = $('.addressData .cookieAddress_delivery_pid');
            const $province = $('.addressData .cookieAddress_delivery_province');
            if($pid.length > 0 && $pid.data('value')) {
                $('.js-address-form #province-id').val($pid.data('value'));
            }else if($province.length > 0 && $province.data('value')) {
                $('.js-address-form #province').val($province.data('value'));
            }
        });
        //bind country change event, load page fire.
        $('.js-country-list').on('change', function(){
            shopJS.ckAddress.ajaxCountryChange($(this));
        });

        //bind address save event
        $('.js-address-save').on('click', function(e){
            e.preventDefault();
            shopJS.report.checkout({step: 1})

            if(shopJS.ckAddress.addressInputCheck()){

                /*Validation yahoo email.-Jkh:190318*/
                // var $email_box=$('.input-address .item-email');
                // var $email_input=$email_box.find('.delivery_email');
                // var $email_err_tips = $email_box.find('.tips-inline')
                // if($email_input.val().toLowerCase().indexOf('yahoo.com')>-1){
                //     $email_err_tips.removeClass('hide');
                //     return false
                // }else {
                //     $email_err_tips.addClass('hide');
                // }

                shopJS.ckAddress.ajaxAddressSave($(this), function(data){
                    shopJS.ckAddress.addAddressList(data.address);
                    shopJS.ckAddress.addressFormHide();
                    if (shopJS.ckAddress.addressList.find('ul').length == 1) {
                        $('.js-address-continue').trigger('click');
                    } else {
                        var scroll_offset = $("ul.cur").offset().top - 10 - $(".top-countdown").height() + 'px';
                        $("body,html").animate({
                            scrollTop: scroll_offset
                        }, 200);
                        $('.fixedbar').removeClass('in');
                        $('.step-shipping .box-wrap').removeClass('hide');
                        $('.step-shipping .service-description').addClass('hide');
                        shopJS.checkout.updateShipping();
                    }

                    // console.log(typeof shopJS.checout.ajaxGetMemberConsume)
                    shopJS.checkout.ajaxGetMemberConsume();


                });
            }
        });

        //show and hide new account box
        $('.js-create-account').on('click', function(e){
            if($(this).prop('checked')){
                $('.js-account-box').removeClass('hide');
            }else{
                $('.js-account-box').addClass('hide');
            }
        });

        //bind create new address event
        $('.js-new-address').on('click', function(){
            shopJS.ckAddress.setAddressInput();
            shopJS.ckAddress.addressFormShow();
        });

        var $addressList = $('.js-address-list');
        //bind address list edit event
        $addressList.on('click','.js-address-edit', function(e){
            e.preventDefault();
            e.stopPropagation();
            shopJS.ckAddress.setAddressInput($(this).closest('ul').data('addressjson'));
            shopJS.ckAddress.addressFormShow();
            var scroll_offset = $(".js-address-form").offset().top - 25 +'px';
            $("body,html").animate({
                scrollTop:scroll_offset
            },200);
            $('.fixedbar').removeClass('in');
        });

        //bind address list click event
        $addressList.on('click', 'ul', function(){
            let $this = $(this);
            let rid = $this.data('rid');
            $('.js-address-list ul').removeClass('cur');
            $(this).addClass('cur');


            $u.http.post('/checkout/address/change',{rid:rid}).then((res)=>{
                let result = res.data;

                if (result.data.cartSummaryView != undefined) {
                    $('.js-cart-summary').replaceWith(result.data.cartSummaryView)
                }

                shopJS.ckAddress.setAddressInput();
                shopJS.ckAddress.addressFormHide();

            }).catch((err)=>console.log(err));
        });

        //bind address title reset event
        $('.js-address-title-edit').on('click', function(){
            shopJS.ckAddress.addressReset();
            shopJS.checkout.shippingReset();
            shopJS.ckPaymnet.paymentReset();
            $('.js-shipping-continue,.js-payment-continue,.btn-place-order').addClass('disabled')
        });

        //bind address continue event
        $('.js-address-continue').on('click', function(e){
            e.preventDefault();

            var $message = $('.step1-message');
            var $selectAddress = $('.js-address-list').find('.cur');
            if($selectAddress.length == 0){
                $message.removeClass('hide').html('please select or input your shipping address!');
                return ;
            }

            shopJS.ckAddress.setSelectedAddress($selectAddress.clone());
            $('.js-address-title-edit').removeClass('hide');
            $('.js-login').addClass('hide');
            $(this).addClass('hide');

            $('.js-shipping-continue').removeClass('disabled');

            shopJS.checkout.shippingReset();
            shopJS.ckPaymnet.paymentReset();

            $('.step-shipping .service-description').addClass('hide');
            $('.step-shipping .box-wrap').removeClass('hide');
            shopJS.checkout.updateShipping();

            var scroll_offset = $('.step-address').height() + $('header').height() + $('.process-tips').height();
            $("body,html").animate({
                scrollTop: scroll_offset
            },200);
            $('.fixedbar').removeClass('in');
        });

        const $addressInput =  $('.js-address-form #address');
        $addressInput.on('blur',function(){
            if($addressInput.val().trim() !== '') {
                $addressInput.removeClass('isSearch')
            }else {
                $addressInput.addClass('isSearch')
            }
        }).on('focus',function(){
            $addressInput.addClass('isSearch')
        }).trigger('blur');
    },

    shippingInit(){
        $('.js-shipping-list').on('click', 'li', function(){
            const $this = $(this);
            shopJS.report.checkout({step: 2});
            if (!$this.hasClass('disabled')) {
                shopJS.ckShipping.ajaxGetShippingFee($this,"click");
            }
        });

        $('.js-rush-list').on('click','li', function(){
            const $this = $(this);
            shopJS.report.checkout({step: 3});
            if(!$this.hasClass('disabled')){
                shopJS.ckRush.ajaxGetRushFee($this, 'click');
            }
        });

        $('#shipping-protection').on('change', function(){
            shopJS.ckShipping.ajaxChooseShippingProtection(!!this.checked)
        })

        $('.js-protection .btn_question').on('click', function(){
            console.log('click btn qu')
            shopJS.common.alert("Get your order reshipped for free if it's lost or damaged during transit.");
        })

        $('.js-shipping-title-edit').on('click', function(e){
            e.preventDefault();
            shopJS.checkout.shippingReset();
            shopJS.ckPaymnet.paymentReset();
            $('.js-payment-continue,.btn-place-order').addClass('disabled')

            $('.step-shipping .service-description').addClass('hide');
            $('.step-shipping .box-wrap').removeClass('hide');
        });

        $('.js-shipping-continue').on('click', function(e){
            e.preventDefault();
            const $this = $(this);
            const $message = $('.step2-message');
            const $shippingElement = $('.js-shipping-list .on');
            if($shippingElement.length == 0 || $shippingElement.hasClass('hide')){
                $message.removeClass('hide').html('please select shipping method!');
                return;
            }

            const $rushElement = $('.js-rush-list .on');
            if($rushElement.length == 0){
                $message.removeClass('hide').html('please select arrival date!');
                return;
            }

            if($this.hasClass('disabled')){
                return false;
            }

            if($shippingElement.length !== 0 && $rushElement.length !== 0){
                $('.js-payment-continue').removeClass('disabled');
            }
            $message.addClass('hide').html('');
            shopJS.ckShipping.shippingSelected($shippingElement.clone());
            shopJS.ckRush.rushSelected($rushElement.clone());
            shopJS.checkout.checkPayment();
            $('.js-shipping-title-edit').removeClass('hide');
            $(this).addClass('hide');

            const $placeOrder = $('.btn-place-order');
            if($placeOrder.data('type') == 'normal'){
                let scroll_offset = $('html').height() +'px';
                $("body,html").animate({
                    scrollTop:scroll_offset
                },600);
                $('.fixedbar').removeClass('in');
            }else if($placeOrder.data('type') == 'paypal-express'){
                $("body,html").animate({
                    scrollTop: $('html').height()
                },200);
                $('.fixedbar').removeClass('in');
            }

        });
    },

    updateShipping(){
        const $shippingList=$('.js-shipping-list li');
        $shippingList.each(function(){
            const $this=$(this);
            shopJS.ckShipping.ajaxGetShippingFee($this, 'init');
        });

        shopJS.checkout.updateRush();
    },

    updateRush(){
        let $rushList = $('.js-rush-list li');
        $rushList.each(function(){
            const $this=$(this);
            shopJS.ckRush.ajaxGetRushFee($this, 'init');
        });
    },
    
    payment : function(){
        $('.js-payment-continue').on('click', function(e){
            e.preventDefault();
            var $message = $('.step3-message');
            var $this =$(this);
            if(!shopJS.ckAddress.getAddress()){
                $message.html('Please select or input your delivery address!').removeClass('hide');
                return false;
            }
            if($this.hasClass('disabled')){
                return false;
            }
            if(!shopJS.ckShipping.getShipping() || shopJS.ckRush.getRush() == undefined){
                $message.html('Please select shipping method and arrival date!').removeClass('hide');
                return false;
            }

            shopJS.ckPaymnet.paymentSelect();
            shopJS.checkout.checkPayment();
        });

        $('.js-payment-title-edit').on('click', function(e){
            e.preventDefault();
            shopJS.ckPaymnet.paymentReset();
            $('.btn-place-order').addClass('disabled')
        });
    }

    ,placeOrder : function(){
        $('.btn-place-order').on('click', function(e){
            e.preventDefault();

            var $this = $(this);
            if($this.hasClass('disabled')){
                return false;
            }

            shopJS.report.checkout({step: 5})

            if(shopJS.checkout.checkPayment()){
                $('#loadingModal').modal('show');
                shopJS.checkout.ajaxCheckoutSubmit($this);
                $this.addClass('disabled');
            }
        });
    }

    ,checkPayment : function(){
        var $message = $('.payment-message ');
        var $placeOrder = $('.btn-place-order');

        if(!shopJS.ckAddress.getAddress()){
            $message.html('Please select or input your delivery address!').removeClass('hide');
            $placeOrder.addClass('disabled');
            return false;
        }

        if(!shopJS.ckShipping.getShipping() || shopJS.ckRush.getRush() == undefined){
            $message.html('Please select shipping method and arrival date!').removeClass('hide');
            $placeOrder.addClass('disabled');
            return false;
        }

        // step2 ignored payment @nosun
        //if($placeOrder.data('type') == 'normal'){
        //    if(!shopJS.ckPaymnet.getPaymentMethod()){
        //        $message.html('Please select payment method!').removeClass('hide');
        //        $placeOrder.addClass('disabled');
        //        return false;
        //    }
        //}

        $message.addClass('hide').html('');
        $placeOrder.removeClass('disabled');
        return true;
    }

    ,shippingReset : function(){
        shopJS.ckRush.rushEdit();
        shopJS.ckShipping.shippingEdit();
        $('.js-shipping-list').find('input').prop('checked', false);
        if (!$('.js-arrival').hasClass('hide')) {
            $('.js-rush-list').find('input').prop('checked', false);
        }

        $('.step-shipping .service-description').removeClass('hide');
        $('.step-shipping .box-wrap').addClass('hide');
        $('.js-shipping-title-edit').addClass('hide');
        $('.js-shipping-continue').removeClass('hide');

    }

    ,setSummaryTotal : function(c_total){
        $('.js-summary-total').html(c_total);
    }

    ,ajaxCheckoutSubmit : function($this){
        let callback = arguments[1] ? arguments[1] : null;
        let data = shopJS.ckAddress.getAddress();
        let $checkoutMessage = $('.payment-message');

        data.shipping_method = shopJS.ckShipping.getShipping();
        data.rush_level = shopJS.ckRush.getRush();
        // 取消 checkout 时选择
        // data.payment_method = $this.data('type') == 'paypal-express' ? 'paypal-express' : shopJS.ckPaymnet.getPaymentMethod();
        data.cartItemIds =  $('#cartItemIds').val();

        $u.http.post('/checkout/submit',data).then(res=>{
            let result = res.data;
            if (result.code == 200 && result.data.success) {
                var json={time:new Date().getTime()};
                window.history.replaceState(json,"",result.data.paymentUrl+'/history');
                if(callback && typeof callback == 'function'){
                    callback(result.data);
                }

                if(result.data.isPaypalExpress != undefined){
                    window.location.href = result.data.redirectUrl;
                    return;
                }
                if(result.data.payment_method == 'paypal'){
                    $('.paypal-form').html(result.data.paypal);
                    $('.paypal-form form').submit();
                    return;
                }

                window.location.href = result.data.paymentUrl;
                return;
            }

            if( result.data.message && !result.data.success ){
                $checkoutMessage.html(result.data.message);
                $checkoutMessage.removeClass('hide');
                $this.removeClass('disabled');
                $('#loadingModal').modal('hide');
            }else{
                $checkoutMessage.addClass('hide');
            }
        }).catch()
    },

    ajaxGetMemberConsume() {
        let $summary=$('.js-cart-summary');
        let $vipTotal=$summary.find('tr.vip-total');
        $u.http.post('/checkout/ajaxGetMemberConsume').then(res=>{
            const data = res.data.data;
            if(res.data.code === 200 && data) {
                // console.log($vipTotal.length)
                if($vipTotal.length) {
                    $vipTotal.find('.key').text(data.name);
                    $vipTotal.find('.value').text(`-${data.benefit_fee_c}`);
                }else {
                    let html=`<tr class="vip-total">
                                  <td class="key">${data.name}:</td>
                                  <td class="value notranslate">-${data.benefit_fee_c}</td>
                              </tr>`
                    $summary.find('tr:first').after(html);
                }
            }else {
                if($vipTotal.length) {$vipTotal.remove();}
            }
        }).catch(()=>{
            if($vipTotal.length) {$vipTotal.remove();}
        })
    },

    pointConsume : function () {
        var $point = $('.js-point-consume');
        if($point.length){
            $point.on('click', function(){
                if($point.prop('checked')){
                    shopJS.checkout.ajaxPointConsume($point.val());
                }else{
                    shopJS.checkout.ajaxPointRemove();
                }
            });
        }
    },

    updateVatFeeItem(vat_fee,vat_fee_c) {
        const $vatFeeItem = $('.js-cart-summary .js-estimated-vat');
        if(vat_fee > 0) {
            $vatFeeItem.find('.value').data('value',vat_fee).text(vat_fee_c)
            $vatFeeItem.removeClass('hide');
        }else{
            $vatFeeItem.addClass('hide');
        }
    }

    ,updateShippingMethodAndRushStatus : function ($obj) {
        var data = $obj.data('value');
        var $taxIncluded = $('.js-shipping-list li[data-value="taxincluded"]');
        var $rush = $('.js-rush-list li[data-value="3"]');

        if ($obj.parents('ul').hasClass('js-rush-list') && $obj.hasClass('on')) {
            if (data == 3) {
                $taxIncluded.addClass('disabled');
            }else{
                $taxIncluded.removeClass('disabled');
            }
        }

        if (
            $obj.parents('ul').hasClass('js-shipping-list') && $obj.hasClass('on') &&
            !($rush.hasClass('disabled') && !($rush.attr('flag-disabled') == -1)) // 非初始化 disabled
        ) {

            if (data == 'taxincluded') {
                $rush.addClass('disabled')
                    .attr('flag-disabled', -1)
                    .find('.tax-disabled-tip')
                    .removeClass('hide')
                    .text('Rush service is unavailable once you select tax-included shipping method.');
            }else{
                $rush.removeClass('disabled')
                    .find('.tax-disabled-tip')
                    .addClass('hide')
                    .text('');
            }
        }
    },

    getCartSummary() {

        let url = '/api/checkout/summary?'
        let address = shopJS.ckAddress.getAddress();
        if(address) url += `&delivery_cid=${address.delivery_cid}`;

        let rushLevel =$('.js-rush-list li.on').data('value');
        if(rushLevel !== undefined)  url += `&rush_level=${rushLevel}`;

        let shippingMethod = $('.js-shipping-list li.on').data('value');
        if(shippingMethod !== undefined)  url += `&shipping_method=${shippingMethod}`;
        
        $u.http.get(url).then(res=>{
            // console.log(res.data.data)
            if(!res.data || res.data.code !== 200) {
                $payIn4Warp.addClass('hide');
                return
            }
            const data = res.data.data;
            let summary = data.cart.summary;
            let html = '';

            /* update discount */
            const $subTotal = $('li.li_subtotal');
            html=''
            $('li.li_discount').remove();
            summary.discounts.forEach(i=>{
                html += `<li class="li_discount">
                    <span>${i.type}:</span>
                    <span class="notranslate">${i.value}</span>
                </li>`
            })
            $subTotal.after(html);

            /* update shipping */
            const $shippingSummary = $('.js-summary-shipping-fee');
            html=''
            summary.shippingFee && $shippingSummary.data('value', summary.shippingFee);
            summary.shippingFeeOriginal_c && summary.shippingFeeOriginal_c !== summary.shippingFee_c && (html += '<del>'+summary.shippingFeeOriginal_c+'</del>');
            summary.shippingFee_c && (html += '<span>'+summary.shippingFee_c+'</span>');
            html && $shippingSummary.html(html);

            /* update shipping protection Fee */
            const $shippingProtection = $('.js-shipping-protection');
            const $shippingProtectionFee = $('.js-shipping-protection-fee');
            if(summary.shippingProtectionFee){
                $shippingProtectionFee.data('value', summary.shippingProtectionFee);
                html = summary.shippingProtectionFee_c;
                html && $shippingProtectionFee.html(html);
                $shippingProtection.removeClass('hide');
            }else{
                $shippingProtection.addClass('hide');
            }

            /* update rush */
            var $rushSummary = $('.js-summary-rush-fee');
            summary.rushFee && $rushSummary.data('value', summary.rushFee);
            summary.rushFee_c && $rushSummary.text(summary.rushFee_c);

            /* update total */
            $('.js-summary-total').html(summary.goodsAmount_c);
            
            /* update pay-in-4 */
            let $payIn4Warp = $('.pay-in-4-wrap');
            if($payIn4Warp.length) {
                let total = data.cart.summary.goodsAmount;
                $payIn4Warp.removeClass('hide').find('.price_one').text(Math.ceil(total/4*100)/100);
            }

        }).catch(err=>{
            console.log(err)
        })
    },

    initCoupon() {
        $('#coupon-code-input-wrap .btn-coupon').on('click', function () {
            let inputTarget = $('#coupon-code-input-wrap .ipt-coupon');
            let appliedCouponCode = $.trim($(this).data('code'));
            let messageBox = $("#coupon-code-input-wrap .coupon-tips");
            let data = {};

            // remove action
            if(appliedCouponCode){
                data.couponCode = appliedCouponCode;
                data.aid = $.trim($(this).data('aid'));
                data.choose = false;
                shopJS.checkout.ajaxCartCoupon(data);
            }else{ // apply action
                let inputCouponCode = inputTarget.val().trim();
                if (!inputCouponCode) {
                    messageBox.html("The coupon code is not usable")
                        .removeClass("success")
                        .addClass("error")
                        .removeClass("hide")
                    return false;
                }
                shopJS.report.checkout({step: 4});

                data.couponCode = inputCouponCode;
                data.choose = true;
                shopJS.checkout.ajaxCartCoupon(data);
            }
        });
    },

    ajaxCartCoupon(data) {
        let address = shopJS.ckAddress.getAddress();
        const $couponTips = $('#coupon-code-input-wrap .coupon-tips');

        data.couponCode = $.trim(data.couponCode);
        data.cartItemIds = $('#cartItemIds').val();
        data.shippingFee = shopJS.ckShipping.shippingFee() || 0;
        data.rushFee = shopJS.ckRush.rushFee() || 0;
        data.delivery_cid = address ? address.delivery_cid : 0;

        $u.http.post('/checkout/ajaxCouponCheck', data).then(res => {
                let result = res.data;
                if (result.code === 200) {
                    if (result.data.success) {
                        const $couponInput = $('#coupon-code-input-wrap .ipt-coupon');
                        const $couponButton = $('#coupon-code-input-wrap').find('.btn-coupon');
                        const coupons = result.data.coupons.filter(i => i.userChoose === true)
                        if (coupons.length > 0) {
                            let coupon = coupons[0];
                            $couponInput.val(coupon.number)
                                .attr('readonly', true)
                                .addClass('readonly');
                            $couponButton.data('id',coupon.aid);
                            $couponButton.data('code',coupon.number);
                            $couponButton.html('REMOVE');
                            $couponTips.removeClass('error')
                            $couponTips.addClass('success')
                            $couponTips.html('Coupon code applied successfully.')
                            $couponTips.removeClass('hide')
                        } else {
                            $couponInput.val('')
                                .attr('readonly', false)
                                .removeClass('readonly')
                            $couponButton.html('APPLY')
                                .data('id','')
                                .data('code','');
                            $couponTips.addClass('hide')
                            $couponTips.html('')
                        }

                        shopJS.checkout.getCartSummary();
                    } else {
                        $couponTips.html("The coupon code is not usable")
                            .removeClass("success")
                            .addClass("error")
                            .removeClass("hide")

                    }
                } else {
                    $couponTips.html(result.data.message)
                        .removeClass("success")
                        .addClass("error")
                        .removeClass("hide")
                }
            }
        )
    },
    ajaxPointConsume(point) {
        $u.http.post('/referal/ajax/cart-point-consume', {
            point: point,
        }).then(res => {
            let result = res.data;
            let $message = $('.js-referal-message');
            if (result.code === 200 && result.data.success) {
                $message.empty();
                shopJS.checkout.getCartSummary();

            } else {
                $message.html(result.data.message);
            }
        }).catch()
    },

    ajaxPointRemove() {

        $u.http.post('/referal/ajax/cart-point-remove', {}).then(res => {
            let result = res.data;
            let $message = $('.js-referal-message');
            if (result.code === 200 && result.data.success) {
                $message.empty();
                shopJS.checkout.getCartSummary();
            } else {
                $message.html(result.data.message);
            }
        }).catch()
    },
};