shopJS.review = {
    init: function () {

    },

    writeInit: function () {
        shopJS.review.initUserInfo();
        shopJS.review.doRating();
        shopJS.review.limitTextarea();
        shopJS.review.reviewImage();
        shopJS.review.submitReview();
    },

    initUserInfo(){
        let $reviewModal = $('#write-review');
        $reviewModal.on('shown.bs.modal', function () {
            let userName = shopJS.common.getUserName();
            let uid = shopJS.common.getUserId()
            let $email = $('#p-review-email');
            let $uid = $(this).find("input[name=uid]");
            if (userName) {
                $email.val(userName);
                $uid.val(uid)
            }
        })
    },

    doRating: function () {
        var $ratingBlock = $('.rating-box .star');
        var originX, targetX;

        $ratingBlock.on('touchstart', function (e) {
            originX = $ratingBlock.offset().left;
            setRating(e, this);
        });
        $ratingBlock.on('touchmove', function (e) {
            setRating(e, this);
        });

        function setRating(event, el) {
            targetX = event.originalEvent.targetTouches[0].pageX;
            var $this = $(el);
            var classStr = $this.attr('class');
            var rating = getRating(originX, targetX);
            if (rating && rating < 6) {
                $this.attr('class', classStr.replace(/level\d/g, 'level' + rating));
                $this.data('rating', rating);
            }

        }

        function getRating(originX, targetX) {
            var starW = 14;
            var starMargin = 6;
            var offsetX = targetX - originX;
            return offsetX >= 0 && offsetX <= 95 ? Math.ceil(offsetX / (starW + starMargin)) : false;
        }
    }

    , limitTextarea: function () {

        var $textarea = $('#p-review-content');

        $textarea.on('keyup keypress paste', function (e) {
            var $this = $(this);
            var strLen = $this.val().length;
            var allowArr = [8, 33, 34, 35, 36, 37, 38, 39, 40, 46]; //editor key.
            var maxLen = 3000;
            //$this.siblings('.tips-remaining').children('span').text(maxLen-strLen);
            if (strLen > maxLen && $.inArray(e.which, allowArr) < 0) {
                $this.val($this.val().substr(0, maxLen));
                e.preventDefault();
            }
        })
    }

    , reviewImage: function () {
        var $box = $('#write-review');

        $box.find('.upload-file').on('change', '#write-file', function (e) {
            var $this = $(this);
            if ($this.val() !== '') {
                //console.log(e);
                var uploadFile = e.target.files[0];
                var errorInfo = $box.find('.is-error');
                if (!(/\.(jpg|jpeg)$/i).test(uploadFile.name)) {
                    errorInfo.text('You must select an jpg photo only.').removeClass('hide');
                    $this.val('');
                } else if (uploadFile.size > 2000000) { // 2mb
                    errorInfo.text('Please upload a smaller image, max size is 2 MB.').removeClass('hide');
                    $this.val('');
                } else {
                    shopJS.review.ajaxUploadImg();
                }
            }
        });

        $box.find('.upload-file').on('click', 'i.btn-remove', function () {
            var $this = $(this);
            var imgId = $this.siblings('img').data('id');
            shopJS.review.ajaxRemoveImg($this, imgId);
        });
    }

    //submit review
    , submitReview: function () {
        $('.btn-review-submit').on('click', function (e) {
            e.preventDefault();
            var photos = [];

            let $email = $('#p-review-email');
            if ($email.val() === '') {
                $('#write-review .is-error').text('Please enter your Email.').removeClass('hide');
                return;
            } else if (!$email.easyVali('email')) {
                $('#write-review .is-error').text('Please input correct Email.').removeClass('hide');
                return;
            } else {
                $('#write-review .is-error').text('').addClass('hide');
            }

            if ($('#p-review-content').val() === '') {
                $('#write-review .is-error').text('Please enter your review.').removeClass('hide');
                return;
            } else {
                $('#write-review .is-error').text('').addClass('hide');
            }

            $('.upload-file .thumb').each(function () {
                photos.push($(this).data('path'));
            });

            $u.http.post('/api/comment/create', {
                pid:      $('#pid').val(),
                rating:   $('.js-review-star').data('rating'),
                content:  $('#p-review-content').val(),
                email:    $email.val(),
                nickname: $('#p-review-nickname').val(),
                photos:   photos
            }).then(res => {
                let result = res.data;
                if (result.code === 200) {
                    $('#write-review .is-success').removeClass('hide');
                    let timer = 3;
                    let closeTimer = setInterval(_ => {
                        if (timer < 0) {
                            clearInterval(closeTimer);
                            $('#write-review').modal('hide');
                            $('.js-review-star').data('rating', 5);
                            $('#p-review-content').val('');
                            $email.val('');
                            $('#p-review-nickname').val('')
                            $('#write-review .is-success').addClass('hide');
                        } else {
                            $('#write-review .is-success span').text(timer);
                            timer--
                        }
                    }, 1000)

                }
            }).catch(err => {
                let res = err.response;
                console.log(res);
                if (res.status === 401) {
                    window.location = '/login';
                }
            })
        });
    }
    //upload image when write reviews.
    , ajaxUploadImg: function () {
        // shopJS.common.axSetup();

        $('.write-review .is-error').text('').addClass('hide');
        var $uploadForm = $('.upload-file-form');
        var fd = new FormData($uploadForm[0]);
        fd.append("type", 'comment');
        fd.append("uid", $("input[name='uid']").val());
        $u.http.post("/api/image/upload", fd).then(response => {
            let res = response.data;
            if (res.code === 200) {
                var html = '<div class="thumb-wrap pull-left">' +
                    '<img class="thumb" ' +
                    'src="' + res.data.url + '"' +
                    'data-id="' + res.data.id + '"'  +
                    'data-path="' + res.data.file_path + '">' +
                    '<i class="ri-delete-bin-line btn-remove"></i>' +
                    '</div>';
                $uploadForm.after(html);
                if ($uploadForm.siblings('.thumb-wrap').length >= 3) {
                    $uploadForm.hide();
                }
            }
        }).catch(err => {
            let res = err.response;
            console.log(res);
            if (res.status === 401) {
                window.location = '/login';
            }
        })
    }
    //remove image after upload images
    , ajaxRemoveImg: function ($this, imgId) {
        var $uploadForm = $('.upload-file-form');
        var file = $('input#write-file')[0];
        // shopJS.common.axSetup();
        $u.http.get("/api/image/delete/" + imgId).then(response => {
            let res = response.data;
            if (res.code === 200) {
                $this.parent().remove();
                if ($uploadForm.siblings('.thumb-wrap').length < 3 && !$uploadForm.is(':visible')) $uploadForm.show();
            }
        }).catch(err => {
            let res = err.response;
            console.log(res);
            if (res.status === 401) {
                window.location = '/login';
            }
        })

        //clear file input value.
        if (file.outerHTML) {
            file.outerHTML = file.outerHTML;
        } else {
            file.value = "";
        }
    }

    , reviewMore: function () {
        $('.p-reviews .js-review-more').on('click', function () {
            let checked = $('input[name=img]:checked').val();
            shopJS.review.ajaxGetAllComments($(this), checked);
        });
    }

    , commentListPage: 2

    , ajaxGetAllComments: function ($element, checked) {
        // shopJS.common.axSetup();
        $u.http.get('/api/static/comment/' + checked + '/' + shopJS.review.commentListPage,).then(res => {
            let result = res.data;
            if (result.code == 200) {
                if (result.data && result.data.comments.length) {
                    const $termComments = $('.js-term-comments');
                    $.each(result.data.comments, function (index, comment) {
                        $termComments.append($(shopJS.review.createCommentList(comment)));
                    });
                    shopJS.review.commentListPage++;
                } else {
                    $element.addClass('hide');
                }
            }
        }).catch();
    }


    , createCommentList: function (comment) {
        // var photo = '<ul class="list-details-img">';
        // for(var i = 0 ; i < comment.photo_paths.length; i++){
        //     photo += '<li>'
        //     photo += '<a href="//'+ window.location.host + '/' + comment.photo_paths[i]+'">'
        //     photo += '<img src="//'+ window.location.host + '/' + comment.photo_paths[i]+'" onerror="shopJS.review.hideImg($(this))">'
        //     photo += '</a>'
        //     photo += '</li>'
        // }
        // photo += '</ul>';
        let content = '<li>';
        content += '<div class="info">';
        content += '<span class="icon-s star level' + comment.grade + '" ></span>';
        content += '<span class="username">BY ' + comment.nickname + '</span>';
        content += '</div>';
        content += '<h3></h3>';
        content += '<p class="content">' + comment.comment + '</p>';
        content += '</li>';

        return content;
    }
};
