


shopJS.like={
    init() {
        const $prodList = $('.prod-list')
        $prodList.on('click','.js-product-like', function (e) {
            e.preventDefault();
            const $this=$(this);
            if($this.hasClass('liked')){
                shopJS.like.ajaxRemoveLike($this).then(res=>{
                    $this.siblings('.count').text(`(${res.data.data})`);
                });
            }else{
                shopJS.like.ajaxLike($this).then(res=>{
                    $this.siblings('.count').text(`(${res.data.data})`);
                    // console.log(res);
                });
            }
        });
    },

    ajaxLike($el) {
        let id = $el.data('pid');
        shopJS.like.reportLike($el);
        return $u.http.get(`/api/favorite/create/${id}`).then((res)=>{
            if(res.data.code === 200) {
                $el.addClass('liked');
                // facebook pixel
                if (shopJS.facebook.status) {
                    let sn = $el.data('sn');
                    shopJS.facebook.addToWish(sn);
                }
                // facebook pixel end
                return res;
            }
        }).catch((err)=>{
            let res = err.response;

        })

    },

    ajaxRemoveLike($el) {
        let id = $el.data('pid');
        return $u.http.get(`/api/favorite/delete/${id}`).then((res)=>{
            if(res.data.code === 200) {
                $el.removeClass('liked');
                return res;
            }
        }).catch((err)=>{
            let res = err.response;
        })
    },

    reportLike($el) {
        let $listItem = $el.parents('.ga4-prod-item');
        let item = null;
        if ($listItem.length > 0) {
            // 产品列表
            item = {
                name: $listItem.data('name'),
                id: $el.data('sn'),
                price: $listItem.find('.price-value').text().trim(),
            }
        } else if ($('body.p-prod').length > 0) {
            // 产品页
            item = {
                name: $('meta[property="og:title"]').attr('content').trim(),
                id: $('meta[property="product:retailer_item_id"]').attr('content').trim(),
                price: $('meta[property="product:price:amount"]').attr('content').trim(),
            }
        }

        // console.log(item)
        if(!item) return
        
        shopJS.report.addToWishList({
            currency: $('.js-currency-slc').val(),
            value: item.price,
            products: [item]
        })
    }
};