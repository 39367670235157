shopJS.payment_asiabill_checkout = {

    initPayment: function (submitButton) {
        const orderNumber = $('[name="js-order-number"]').val();

        submitButton.on("click", function () {
            // console.log($(this).data('payment-method'))
            let paymentMethod = $(this).data('payment-method');
            let $errBox = $(this).prev(".payment-message");

            shopJS.payment.checkPaymentFrom('set');
            shopJS.report.checkout({step: 7});

            $errBox.addClass('hide');

            // shopJS.common.axSetup();
            shopJS.common.loadingModal('show')

            $u.http.post('/payment/pay', {
                payment_method: paymentMethod,
                order_number:   orderNumber,
            }).then(res => {
                shopJS.common.loadingModal('hidden')
                let data = res.data;
                if (data.code === 200) {
                    if (data.data.status === 'success') {
                        window.location.href = data.data.redirect_url;
                    } else {
                        errBox.html(data.data.message).removeClass('hide');
                        shopJS.common.loadingModal('hidden')
                    }
                }
            }).catch(err => {
                console.log(err)
                $errBox.html('Failed, please try again.').removeClass('hide')
                shopJS.common.loadingModal('hidden')
            })
        })
    },
};