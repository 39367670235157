
shopJS.pin = {
    init() { },

    convertToPinItems(products = []){
        let items = [];
        products.forEach((i)=>{
            let item = {};
            if(typeof i.name !== "undefined") {
                item['product_name'] = i.name;
            }
            if(typeof i.id !== "undefined") {
                item['product_id'] = i.id;
            }
            if(typeof i.price !== "undefined") {
                item['product_price'] = i.price;
            }
            if(typeof i.quantity !== "undefined") {
                item['product_quantity'] = i.quantity;
            }else {
                item['product_quantity'] = 1;
            }

            if(typeof i.category !== "undefined") {
                item['product_category'] = i.category;
            }else {
                item['product_category'] = 'Dresses';
            }

            item['product_brand'] = 'Dress Afford'

            items.push(item)
        })

        return items
    },

     /* detail
    * */
    detail(products=[]){
        let items = shopJS.pin.convertToPinItems(products)
        dataLayer.push({
            'event': 'pinEvent',
            'pin_event_type':'detail',
            'pin_currency':'USD',
            'pin_line_items': items
        });
    },


     /* Add to cart.
    * */
    addToCart(products=[]){
        if(products.length<=0) return;
        let items = shopJS.pin.convertToPinItems(products)
        dataLayer.push({
            'event': 'pinEvent',
            'pin_event_type':'addToCart',
            'pin_value':products[0].price,
            'pin_currency':'USD',
            'pin_line_items': items
        });
    },


    checkout(value,currency,orderNumber,email,products=[]) {
        let items = shopJS.pin.convertToPinItems(products)
        dataLayer.push({
            'event': 'pinEvent',
            'pin_event_type':'checkout',
            'pin_email': email,
            'pin_value': value,
            'pin_order_qty': items.length,
            'pin_currency': currency,
            'pin_order_id': orderNumber,
            'pin_line_items': items
        });
    }

};