shopJS.user = {

    info: function () {
        // shopJS.common.axSetup();

        // facebook pixel
        if (shopJS.facebook.status) {
            shopJS.facebook.Registed();
        }
        // facebook pixel

        $('.checkbox').on('keypress', 'input', function () {
            $(this).siblings('p').css('display', 'none');
        }).on('click', 'select', function () {
            $(this).siblings('p').css('display', 'none');
        });

        $('.update-btn').on('click', function (e) {
            e.preventDefault();
            var error = 0;
            if ($('select.js-info-day').val() && $('select.js-info-month').val() && $('select.js-info-year').val()) {
                var birthday = $('select.js-info-year').val() + $('select.js-info-month').val() + $('select.js-info-day').val();
                $('.js-info-form input[name="birthday"]').val(birthday);
            }
            $('.personal-form input').each(function () {
                if ($(this).attr('name') != 'gender') {
                    if ($(this).val() == '') {
                        $(this).siblings(".warn-w").show();
                        error += 1;
                    } else {
                        $(this).siblings('.edit-wain').hide();
                    }
                }
            });
            if (error == 0) {
                $('.js-info-form').submit();
                shopJS.common.success('Your personal information has been updated');
            }
        });

        $('.js-verify-email').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            if ($this.hasClass('disabled')) {
                return;
            }

            var $email = $('.js-info-form input[name="email"]');
            var email = $email.val();

            var error = 0;

            if (!$email.easyVali('email')) {
                $email.siblings('p').removeClass('warn-tit').html('Please enter the correct Email Address').show();
                error += 1;
            }

            if (!$email.easyVali('required')) {
                $email.siblings('p').removeClass('warn-tit').html('Please enter your Email Address').show();
                error += 1;
            }

            if (error > 0) {
                return;
            }

            $this.addClass('disabled');

            $u.http.post('/verify',{
                'email': email
            }).then(res=>{
                let result = res.data;
                if (result.code == 200) {
                    shopJS.common.alert('Verify email has been sent, please login you email and verify your account.');
                }
            })
        });
    },

    addressEdit () {
        // shopJS.common.axSetup();

        const $addressWrap = $('.js-address');
        const $countrySelect = $('.js-country');
        const $provinceInput = $('.js-province-input');
        const $provinceSelect = $('.js-province-select');

        $addressWrap.on('change', '.js-country', function () {
            ajaxCountryChange($(this));
        });

        function ajaxCountryChange ($this) {
            let cid = $this.val();
            $u.http.post('/checkout/ajaxGetProvince',{
                delivery_cid: cid
            }).then(res=>{
                let result = res.data;
                if (result.code == 200 && result.data.success) {
                    if (result.data.provinces.length == 0) {
                        $provinceInput.removeClass('hide');
                        $provinceInput.val('');
                        $('.js-province-select').addClass('hide');
                    } else {
                        $provinceSelect.empty();
                        $provinceSelect.append($('<option value="" >Please Choose Your State/Province</option>'));
                        $.each(result.data.provinces, function (index, province) {
                            $provinceSelect.append($('<option value="' + province.pid + '">' + province.province_name + '</option>'));
                        });

                        $provinceInput.addClass('hide');
                        $('.js-province-select').removeClass('hide');
                    }
                }
            })
        }

        $addressWrap.on('click', '.js-save-address', function (e) {
            e.preventDefault();

            var error = 0;
            var checkEmail = function ($element) {
                return $element.attr('name') != 'delivery_email' || $element.easyVali('email');
            };

            $('.js-address input').each(function () {
                if (!$(this).hasClass('hide') && ($(this).val() == '' || !checkEmail($(this)))) {
                    $(this).siblings('.is-error').removeClass('hide');
                    error += 1;
                } else {
                    $(this).siblings('.is-error').addClass('hide');
                }
            });


            if (!$countrySelect.val()) {
                $('.js-country-wain').removeClass('hide');
                error += 1;
            } else {
                $('.js-country-wain').addClass('hide');
            }

            if (!$provinceInput.val() && !$provinceSelect.val()) {
                $('.js-province-wain').removeClass('hide');
                error += 1;
            } else {
                $('.js-province-wain').addClass('hide');
            }

            if (error == 0) {
                var formData = {
                    'delivery_first_name': $('input[name=delivery_first_name]').val(),
                    'delivery_last_name': $('input[name=delivery_last_name]').val(),
                    'delivery_country': $countrySelect.find('option[value="' + $countrySelect.val() + '"]').text(),
                    'delivery_province': $provinceInput.val(),
                    'delivery_cid': $countrySelect.val(),
                    'delivery_pid': $provinceSelect.val(),
                    'delivery_city': $('input[name=delivery_city]').val(),
                    'delivery_address': $('input[name=delivery_address]').val(),
                    'delivery_mobile': $('input[name=delivery_mobile]').val(),
                    'delivery_email': $('input[name=delivery_email]').val(),
                    'delivery_postcode': $('input[name=delivery_postcode]').val()
                };
                let method = $addressWrap.data('method');
                $u.http[method]($addressWrap.data('url'),formData).then(res=>{
                    let result = res.data;
                    if (result.code == 200) {
                        window.location = '/address';
                    } else {
                        alert(result.data.message);
                    }
                }).catch(err=>{
                    console.log(err.response);
                    shopJS.common.responseErrorOutput(err);
                })
            }
        });
    },

    address () {
        // shopJS.common.axSetup();

        const $addressConfirm = $('#address-confirm');

        $(".js-address-delete").on('click', function (e) {
            e.preventDefault();
            $addressConfirm.find('.ok').data('rid', $(this).data('rid'));
            $addressConfirm.modal('show');

        });

        $addressConfirm.on('click', '.ok', function (e) {
            e.preventDefault();
            if ($(this).data('rid')) {

                $u.http.delete('/address/' + $(this).data('rid')).then(res=>{
                    let result = res.data;
                    if (result.code == 200) {
                        location.reload();
                    } else {
                        alert(result.data.message);
                    }
                })
            }
        });
    },

    clearHistory: function () {
        $('.js-remove-all-history').on('click', function () {
            $u.http.post('/api/history/delete').then(res=>{
                location.reload();
            }).catch(err=>{
                console.log('Faild about remove history list.');
            })
        });
    },

    removeFavorite: function () {
        $('.js-remove-favorite').on('click', function () {
            var pid = $(this).data('pid');
            var $item = $(this).parents('.prod-item');
            shopJS.common.confirmModal({
                text: 'Are you sure you want to delete this item?'
            }, function () {
                //console.log(pid, '--modal callback');
                $u.http.get('/api/favorite/delete/' + pid).then(res=>{
                    $item.remove();
                }).catch(err=>{
                    console.log('Faild about remove favorite.');
                })
            });
        });
    },

    closeNewUserFixedTips() {
        let $wrap = $('.new-user-fixed');
        if($wrap && $wrap.length > 0){
            let $btnClose = $wrap.find('.btn-close');
            let cookieKey = 'newUserFixedTips';
            let userLoginStatus = shopJS.common.getUserLoginStatus();

            // 如果登录，不展示
            if(userLoginStatus === true){
                return;
            }

            if(!Cookies.get(cookieKey)) {
                $wrap.removeClass('hide');
                $btnClose.on('click',function(){
                    $wrap.remove();
                    Cookies.set(cookieKey,'1',{
                        expires: 1,
                    })
                })
            }
        }

    }
};