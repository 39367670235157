

shopJS.ckShipping = {
    shippingList : $('.js-shipping-list li'),

    // shippingItemSelected: $('.js-shipping-list li.on'),

    selectedShipping : $('.js-selected-shipping'),

    shippingContinue: $('.js-shipping-continue'),

    shippingFee : function(fee,fee_c,fee_original_c){
        const $shippingSummary = $('.js-summary-shipping-fee');
        // let html = ''

        // fee && $shippingSummary.data('value', fee);
        // fee_original_c && fee_original_c !== fee_c && (html += '<del>'+fee_original_c+'</del>');
        // fee_c && (html += '<span>'+fee_c+'</span>');
        // html && $shippingSummary.html(html);

        return $shippingSummary.data('value');
    },

    ajaxGetShippingFee : function($element,event){
        let callback = arguments[2] ? arguments[2] : null;
        let shippingKey = $element.data('value');
        let $shippingErrorMessage = $('.shipping-error-message');
        let address = shopJS.ckAddress.getAddress();

        if(!address){
            $shippingErrorMessage.html('Please select or input shipping address!');
            $shippingErrorMessage.removeClass('hide');
            return false;
        }else{
            $shippingErrorMessage.html('');
            $shippingErrorMessage.addClass('hide');
        }

        $u.http.post('/checkout/ajaxGetShippingFee',{
            rush_level : $('.js-rush-list li.on').data('value'),
            shipping_method : shippingKey,
            delivery_cid : address.delivery_cid,
            delivery_pid : address.delivery_pid != undefined ? address.delivery_pid : 0,
            rush_fee : shopJS.ckRush.rushFee(),
            cartItemIds : $('#cartItemIds').val()
        }).then(res=>{
            if(!res.data || !res.data.data){
                throw('ajax getshipping fee error');
                return;
            }

            let data = res.data.data;

            if (res.data.code == 200 && data.method_checked && data.success && $element.data('disable') != '1') {
                let html = '';
                if(data.shipping_fee_c !== data.shipping_fee_original_c) {
                    html += `<del>${data.shipping_fee_original_c}</del> &nbsp;`
                }
                html += data.shipping_fee_c
                $element.find('.js-shipping-fee').html(html);
                $element.find('.s_time').text(data.description);

                if(data.shipping_fee == 0){
                    $element.find('.js-free-shipping-tips').removeClass('hide');
                }else{
                    $element.find('.js-free-shipping-tips').addClass('hide');
                }

                if (data.disabled) {
                    $element.addClass('disabled');
                }else{
                    $element.removeClass('disabled');

                    if (event == 'click') {
                        shopJS.ckShipping.shippingList.removeClass('on');
                        $element.addClass('on').removeClass('hide');
                        $element.find('input').prop('checked', true);
                        shopJS.checkout.updateShippingMethodAndRushStatus($element);
                        shopJS.checkout.updateRush();
                        shopJS.checkout.getCartSummary();
                    }
                }

                if($element.hasClass('on')) {
                    shopJS.checkout.updateVatFeeItem(data.vat_fee,data.vat_fee_c)
                }
            }

            if(!data.method_checked || $element.data('disable') == '1'){
                $element.addClass('hide');
            }else {
                $element.removeClass('hide');
            }

            if( data.message && !data.success ){
                $element.find('input').prop('checked', false);
            }else{
                $shippingErrorMessage.html('');
                $shippingErrorMessage.addClass('hide');
            }

            if(callback && typeof callback == 'function'){
                callback(result);
            }
        }).catch()
    },

    shippingEdit : function(){
        $('.js-shipping-list').removeClass('hide');
        shopJS.ckShipping.shippingList.removeClass('on');
        shopJS.ckShipping.selectedShipping.addClass('hide').empty();
    },

    shippingSelected : function($element){
        shopJS.ckShipping.selectedShipping.empty().append($element);
        $('.js-shipping-list').addClass('hide');
        shopJS.ckShipping.selectedShipping.removeClass('hide');

    },

    getShipping : function(){
        var $element = shopJS.ckShipping.selectedShipping.find('li');
        return $element.length ? $element.data('value') : undefined;
    },

    ajaxSelectShipping : function () {
        if ($('.js-shipping-list li.on').length > 0) {
            shopJS.ckShipping.ajaxGetShippingFee($('.js-shipping-list li.on'),"click");
            if (shopJS.ckShipping.shippingContinue.hasClass('hide')) {
                shopJS.ckShipping.shippingList.addClass('hide');
                shopJS.ckShipping.shippingSelected($('.js-shipping-list li.on').clone());
            }
        }
    },
    ajaxChooseShippingProtection(isChecked){
        $u.http.post('/checkout/ajaxChooseShippingProtection',{
            checked : isChecked,
        }).then(res=>{
            let result = res.data;
            if (result.code === 200 && result.data.success) {
                shopJS.checkout.getCartSummary();
            }
        }).catch()
    }
};