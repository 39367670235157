shopJS.payment_asiabill_credit_card = {

    init() {
        let sessionToken = $('#payment-info-data').data('session_token');
        let mode = $('#payment-info-data').data('mode');
        shopJS.payment_asiabill_credit_card.sessionToken = sessionToken;
        shopJS.payment_asiabill_credit_card.mode = mode;
        shopJS.payment_asiabill_credit_card.initPayment();
    },
    sessionToken: null,
    ab:           null,
    mode:         null,

    initPayment() {
        const $asiabillInput = $('.js-ab-payment input');
        const $errBox = $(".creditcard-payment-message");

        $asiabillInput.on('click', function (e) {
            $errBox.addClass('hide');

            if (!shopJS.payment_asiabill_credit_card.sessionToken) {
                shopJS.common.confirmModal({
                    text:   'Credit card payment init error, please refresh and try again.',
                    okText: 'Refresh'
                }, function () {
                    window.location.reload();
                });
            }

            shopJS.payment_asiabill_credit_card.ab = AsiabillPay(shopJS.payment_asiabill_credit_card.sessionToken);
            shopJS.payment_asiabill_credit_card.initElement();
            shopJS.payment_asiabill_credit_card.bindSubmit();
        });
    },

    getPaymentMethodObj() {
        let $dataEl = $('#payment-info-data');
        return {
            "billingDetail": {
                "address":   {
                    "city":       $dataEl.data('address-city'),
                    "country":    $dataEl.data('address-country'),
                    "line1":      $dataEl.data('address-line1'),
                    "line2":      $dataEl.data('address-line2'),
                    "postalCode": $dataEl.data('address-postal_code'),
                    "state":      $dataEl.data('address-state')
                },
                "email":     $dataEl.data('address-email'),
                "firstName": $dataEl.data('address-first_name'),
                "lastName":  $dataEl.data('address-last_name'),
                "phone":     $dataEl.data('address-phone'),
            },
            "card":          {
                "cardNo":           "",
                "cardExpireMonth":  "",
                "cardExpireYear":   "",
                "cardSecurityCode": "",
                "issuingBank":      ""
            },
            "customerId":    "",
            "signInfo":      ""
        };
    },

    bindSubmit() {
        const $asiabillButton = $('.js-btn-payment');
        const $errBox = $(".creditcard-payment-message");

        $asiabillButton.on('click', function () {
            if ($(this).hasClass("disabled")) {
                return;
            }

            if ($('.js-ab-payment input.on').length < 1) {
                return
            }

            // submit report
            shopJS.payment.checkPaymentFrom('set');
            shopJS.report.checkout({step: 7});

            shopJS.payment_asiabill_credit_card.ab.confirmPaymentMethod({
                apikey:     shopJS.payment_asiabill_credit_card.sessionToken,
                trnxDetail: shopJS.payment_asiabill_credit_card.getPaymentMethodObj()
            }).then(result => {
                // console.info("confirmPaymentMethod-----");
                // console.log(result)
                if (result.data.code === "0") {
                    shopJS.payment_asiabill_credit_card.requestToBackEnd(result);
                } else {
                    // console.log(result.data.message);
                    $errBox.text(result.data.message).removeClass('hide')
                }
            }).catch(err => {
                console.log(err)
            })
        });
    },

    requestToBackEnd(result) {
        let orderNumber = $('[name="js-order-number"]').val();
        const $errBox = $('.creditcard-payment-message');

        $errBox.addClass('hide');
        // shopJS.common.axSetup();
        shopJS.common.loadingModal('show')

        $u.http.post('/payment/pay', {
            customerPaymentMethodId: result.data.data.customerPaymentMethodId,
            payment_method:          'asiabill',
            order_number:            orderNumber,
        }).then(res => {
            shopJS.common.loadingModal('hidden')
            let data = res.data;
            if (data.code === 200) {
                switch (data.data.status) {
                    case 'success':
                    case 'paid':
                    case 'pending':
                        window.location.href = data.data.redirect_url;
                        break;
                    case 'verify':
                        let delay = 6000;

                        setTimeout(() => {
                            shopJS.common.toastModal({
                                delay: delay,
                                id:    'payment',
                                icon:  '/build/images/global/icon_safe.png',
                                html:  'Payment 3D secure verification in process. Directing you to an authentication page.',
                                callback() {
                                    // console.log('toast callback.')
                                    window.location.href = data.data.redirect_url;
                                }
                            })
                        }, 1000)

                        break;
                    case 'failed':
                        $errBox.html(data.data.message).removeClass('hide')
                        break;
                    default:
                        break;
                }
            }
        }).catch(err => {
            shopJS.common.loadingModal('hidden')
            alert('Failed, please try again.');
            console.log(err)
        })
    },

    initElement() {
        shopJS.payment_asiabill_credit_card.ab.elementInit("payment_steps", {
            formId:       'payment-ab-form', // 放置区域的表单Id
            frameId:      'privateFrame', // 预生成的Iframe标签的Id
            mode:         shopJS.payment_asiabill_credit_card.mode, // 脚本模式 uat | pro
            customerId:   '', // 当前支付流程获取的客户Id
            needCardList: true, // 客户绑定支付方式后，展示付款区域时是否默认展示支付方式列表
            needCardLogo: true, // 支付方式列表是否需要展示卡logo
            autoValidate: true, // 是否支持展示校验信息，默认展示
            layout:       {
                pageMode: 'block', // 托管区域的布局模式 inner | block
                style:    {
                    frameMaxHeight: '100', // iframe的高度
                    input:          {
                        Color:           "#32325d", // 托管区域的字体颜色
                        ContainerBg:     "#ffffff", // 托管区域的背景色
                        ContainerBorder: "2", // 托管区域的边框
                        BorderRadius:    '0',
                        ContainerSh:     "none", // 托管区域的阴影效果
                        FontFamily:      "Arial", // 托管区域的字体
                        FontSize:        "16", // 托管区域的字体大小
                        FontWeight:      "400" // 托管区域的字体粗细
                    }
                }
            }
        }).then((res) => {
            // shopJS.common.loadingModal('hidden');
            // 初始化成功后，商户可以在此区域执行其他操作。
            // 例如，不需要sdk自带的支付方式列表效果时,获取列表内容后可自定义展示效果。
            let paymentMethodList = res.data.paymentMethodList;
            // your code
            // console.log("initRES", res)
        }).catch(function (err) {
            // console.log("initERR", err)
        });
    },
};