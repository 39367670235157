

shopJS.ckPaymnet = {
    getPaymentMethod : function(){
        var $selectedPayment = $('.js-payment-selected input:checked');
        return $selectedPayment.length ? $selectedPayment.val() : undefined;
    }

    ,paymentReset: function(){
        $('.js-payment-selected').empty().addClass('hide');
        $('.js-payment-list').removeClass('hide').find('input').prop('checked', false);
        $('.js-payment-title-edit').addClass('hide');
        $('.js-payment-continue').removeClass('hide');
    }

    ,paymentSelect : function(){
        var $selectedPayment = shopJS.ckPaymnet.getSelectedPayment();
        if($selectedPayment.length){
            $('.js-payment-selected').empty().append($selectedPayment.clone()).removeClass('hide');
            $('.js-payment-list').addClass('hide');
            $('.js-payment-title-edit').removeClass('hide');
            $('.js-payment-continue').addClass('hide');

            $("body,html").animate({
                scrollTop: $('html').height()
            },200);
            $('.fixedbar').removeClass('in');
        }
    }

    ,getSelectedPayment : function(){
        var showMessage = arguments[0] ? arguments[0] : true;
        var $selectedPayment = $('.js-payment-list input:checked').closest('tr');
        if(showMessage){
            var $message = $('.step3-message');
            if(!$selectedPayment.length){
                $message.html('Please select payment method!').removeClass('hide');
            }else{
                $message.addClass('hide').html('');
            }
        }
        return $selectedPayment;
    }
};