


shopJS.ckRush = {
    rushList : $('.js-rush-list li'),
    selectedRush : $('.js-selected-rush'),

    rushFee : function(){
        return $('.js-summary-rush-fee').data('value');
    },

    ajaxGetRushFee : function($element, event){
        let callback = arguments[2] ? arguments[2] : null;
        let $rushErrorMessage = $('.rush-error-message');
        let rush_level = $element.data('value');
        let address = shopJS.ckAddress.getAddress();
        let shippingMethod = $('.js-shipping-list li.on').data('value');

        let $disabledTip = $element.find('.tax-disabled-tip');

        if(!address){
            $rushErrorMessage.html('Please select or input shipping address!');
            $rushErrorMessage.removeClass('hide');
            return false;
        }else{
            $rushErrorMessage.html('');
            $rushErrorMessage.addClass('hide');
        }

        $u.http.post('/checkout/ajaxGetRushFee',{
            shipping_method : shippingMethod,
            delivery_cid : address.delivery_cid,
            rush_level : rush_level,
            shipping_fee : shopJS.ckShipping.shippingFee(),
            cartItemIds : $('#cartItemIds').val()
        }).then(res=>{
            let result = res.data;
            if (result.code == 200 && result.data.success) {

                $element.find('.a_date').text(result.data.arrival_date)

                if (result.data.disabled) {
                    $element.addClass('disabled');
                    if (result.data.message) {
                        $disabledTip.removeClass('hide').text(result.data.message);
                    }else{
                        $disabledTip.addClass('hide').text('');
                    }

                }else{
                    $disabledTip.addClass('hide').text('');
                    $element.removeClass('disabled');

                    if (event == 'click') {
                        shopJS.ckRush.rushList.removeClass('on');
                        $element.addClass('on');
                        $element.find('input').prop('checked',true);
                        shopJS.checkout.updateShippingMethodAndRushStatus($element);
                        shopJS.checkout.getCartSummary();
                    }
                }

                if(callback && typeof callback == 'function'){
                    callback(result);
                }

            }

            if( result.data.message && !result.data.success ){
                $rushErrorMessage.html(result.data.message);
                $rushErrorMessage.removeClass('hide');
                $element.find('input').prop('checked',false);
            }else{
                $rushErrorMessage.html('');
                $rushErrorMessage.addClass('hide');
            }
        }).catch()
    },

    rushEdit : function(){
        if (!$('.js-arrival').hasClass('hide')) {
            shopJS.ckRush.rushList.removeClass('hide').removeClass('on');
        }
        shopJS.ckRush.selectedRush.addClass('hide').empty();
    },

    rushSelected : function($element){
        shopJS.ckRush.selectedRush.empty().append($element);
        shopJS.ckRush.rushList.addClass('hide');
        shopJS.ckRush.selectedRush.removeClass('hide');
    },

    getRush : function(){
        var $element = shopJS.ckRush.selectedRush.find('li');
        return $element.length ? $element.data('value') : undefined;
    }
};

