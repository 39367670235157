shopJS.flashSale = {
    init: function () {},

    initFlashSaleCountdown: function (el) {
        const $clock = $(el);
        const $day = $clock.find('.day .s1');
        const $hour = $clock.find('.hour .s1');
        const $minute = $clock.find('.minute .s1');
        const $second = $clock.find('.second .s1');
        let deadline = $clock.data('deadline');

        if (!deadline || deadline < 3) {
            return
        }
        const clock = new Countdown(deadline, {
            everySecondFn: function (date) {
                $day.text(date.day);
                $hour.text(date.hourToDay);
                $minute.text(date.minute);
                $second.text(date.second);
            },
            finalFn: function (date) {
                console.log('final')
            }
        });

        clock.start()
    },
};
