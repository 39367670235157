
shopJS.ga = {
    init() { },

    callbackTimer:[],
    callbackDelayTime: 1000, /* GA回调函数若未在1秒内执行，则自动执行*/

    /**
     * @param  { Function | undefined | null } callback
     * @return { Function }
     * */
    buildCallbackClosure(callback) {
        if(typeof callback !== 'function') {
            return function(){}
        }

        let cbID=shopJS.ga.callbackTimer.length;
        let timer = setTimeout(()=>{
            shopJS.ga.execCallback(callback,cbID)
        }, shopJS.ga.callbackDelayTime)
        shopJS.ga.callbackTimer.push(timer);
        return function(){
            shopJS.ga.execCallback(callback,cbID)
        }
    },

    /**
     * @param  { Function | undefined } callback
     * @param  { Number }  cbID  - callback id & timer index.
     * @return { void }
     * */
    execCallback(callback,cbID) {
        if (shopJS.ga.callbackTimer[cbID] >= 0) {
            clearTimeout(shopJS.ga.callbackTimer[cbID]);
            shopJS.ga.callbackTimer[cbID] = -1;
            callback();
        }
    },

    /* a view of product detail.
    *
    * e.g.
    * {
    *   'name': 'Android T-Shirt',         // Name or ID is required.
    *   'id': '12345',
    *   'price': '15.25',
    *   'brand': 'Apple',
    *   'category': 'Apparel',
    *   'variant': 'Gray'
    *  }
    *
    * */
    detail(products=[], list=''){
        let detail = {products: products};
        if(list) {
            detail.actionField = {list:list}
        }
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            'event': 'uaEvent',
            'eventCategory': 'trade',
            'eventLabel': 'detail',
            'eventAction': 'view product detail',
            'ecommerce': {
                'detail': detail
            }
        });
    },

    /* Add to cart.
    * */
    addToCart(products=[],callback){
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            'event': 'uaEvent',
            'eventCategory': 'trade',
            'eventLabel': 'addToCart',
            'eventAction': 'add to cart',
            'ecommerce': {
                'currencyCode': 'USD',
                'add': {
                    products
                }
            },
            eventCallback(){
                if(typeof callback === 'function') {
                    callback();
                }
            }
        });
    },

    /* Remove from cart.
    * */
    removeFormCart(products=[],callback){
        let callbackClosure = shopJS.ga.buildCallbackClosure(callback);
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            'event': 'uaEvent',
            'eventCategory': 'trade',
            'eventLabel': 'removeFormCart',
            'eventAction': 'remove from cart',
            'ecommerce': {
                'remove': {
                    products
                }
            },
            eventCallback(){
                callbackClosure();
            }
        });
    },

    /**
     *  checkout.
     *  @param  { Number } step
     *  @param  { String } stepString
     *  @param  { String } checkoutOption - the option of current step.
     * */
    checkout(step,stepString,checkoutOption='') {
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            'event': 'uaEvent',
            'eventCategory': 'trade',
            'eventLabel': 'checkout',
            'eventAction': stepString,
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': step, 'option': checkoutOption}
                }
            },
            eventCallback(){
                // console.log(step,checkoutOption);
            }
        });
    },

    /**
     * @desc  payment success
     * @param {object} action
     * @param {array}  products
     *
     * */
    purchase(action={},products=[]) {
        // console.log('ga purchase');
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            'event': 'uaEvent',
            'eventCategory': 'trade',
            'eventLabel': 'purchase',
            'eventAction': 'purchase',
            'eventValue': 'success',
            'ecommerce': {
                'currencyCode': 'USD',
                'purchase': {
                    'actionField': action,
                    'products': products
                }
            },
            eventCallback(){
                // console.log('')
            }
        });
    },

    /**
     * @name  Monitor
     *
     * @param {object} data - {category(monitor),label(page),action(click position),value()}
     * @param {function|null} callback
     * @return {void}
     *
     * */
    monitor(data,callback=null) {
        let callbackClosure = shopJS.ga.buildCallbackClosure(callback);
        let opt = Object.assign({
            category:'monitor',
            label:'unknown',
            action:'unknown',
            value:''
        },data)
        // console.log(data);
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            'event': 'uaEvent',
            'eventCategory': opt.category,
            'eventAction': opt.action,
            'eventLabel':  opt.label,
            'eventValue':  opt.value,
            eventCallback() {
                callbackClosure();
            }
        });
    },

};