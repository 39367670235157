


shopJS.color={
    colorInit : function(){
        var $colorLists=$('.js-color-list li');
        var $swatchBtn=$('.btn-swatch');

        $colorLists.on('click', function () {
            var $this=$(this);
            $colorLists.removeClass('cur');
            $this.addClass('cur');
            $('#attr_color').val($this.data('value'));
            $('.js-color-warn').addClass('hide');
            if($swatchBtn.length) {
                $swatchBtn.find('i').attr({'class':$this.find('a').attr('class')});
            }
        });
    }

    //belt color
    ,beltColorInit: function () {
        var $beltLists=$('.js-belt-list li');
        $beltLists.on('click', function () {
            var $this=$(this);
            $beltLists.removeClass('cur');
            $this.addClass('cur');
            $('#attr_beltcolor').val($this.data('value'));
            $('.js-belt-color-warn').addClass('hide');
        });
    }

    ,titleTips: function () {
        //Add hint info for color block,rely on bootstrap.js
        $("[data-toggle='tooltip']").tooltip();
    }

    ,colorChart: function () {
        const $colorChartModal = $('#color-chart');
        const $selectForm = $('.js-fabric-select');

        $u.http.get('/api/product/color_charts').then(res=>{
            // console.log(res);
            if(res.data.code === 200){
                let colorChartList = res.data.data;

                $.each(colorChartList, function (index, colorChartItem) {
                    const $ul = $(`<ul class="color-chart-box js-fabric f-${colorChartItem.fabric} ${colorChartItem.fabric === 'Satin' ? '':'hide'}"></ul>`);
                    $.each(colorChartItem.list, function (index,item) {
                        let li = '<li>' +
                            '<span class="color '+item.replace(' ','_')+' ">' +
                            '</span>' +
                            '<div class="show-table">' +
                            '<div class="show-cell">' +
                            '<span>' + item + '</span></div></div></li>';
                        $ul.append(li);
                    });
                    for (let i = 0; i <= 4; i++) {
                        const $liEmpty = $('<li class="empty-li"></li>');
                        $ul.append($liEmpty);
                    }
                    $colorChartModal.find('.color-chart').append($ul);
                    $selectForm.append(`<option value="${colorChartItem.fabric}" ${colorChartItem.fabric === 'Satin' ? 'selected' : ''}>${colorChartItem.fabric.replace(/[_-]+/g,' ')}</option>`)
                })
                $colorChartModal.on('change', '.js-fabric-select', function () {
                    $('.js-fabric').addClass('hide');
                    $('.f-' + $(this).val()).removeClass('hide');
                });
            }
        })
    }

};