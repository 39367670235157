shopJS.category = {
    init: function () {
        shopJS.static.categoryStatic();
        shopJS.googleAds.googleAds('category');
        // shopJS.category.insertAdToList();
        shopJS.category.setViewImgModel();

        shopJS.report.viewTerm({
            termID:$('ul.prod-list').data('term-id'),
            requestID:$('ul.prod-list').data('request-id'),
            termName:$('ul.prod-list').data('term-name'),
            termPath:$('ul.prod-list').data('term-path'),
            termPage:$('ul.prod-list').data('term-page'),
            sns:JSON.parse($('.js-list-sns').val()),
            url:window.location.href,
        })
    },

    getImage: function () {
        let $pImgs = $('img.prod-img');
        $pImgs.each(function () {
            var $this = $(this);
            var src = $this.data('src');
            if (!src) {
                return
            }
            var img = new Image();
            img.src = src;
            img.onload = function () {
                $this.attr('src', src);
            };
        })
    },

    insertAdToList() {
        let $isShowAd = $('.inset-ad-to-list-mark');
        let $tid = $('.js-tid');
        let $item = $('.prod-list .prod-item');

        if ($isShowAd.length < 1) return;

        //  ajax request data for the server.
        $u.http.get(`/api/activity/categoryAd/${$tid.val()}`).then(response=>{
            let res = response.data;
            if (res.code == 200 && res.data.category_ad != null) {
                let ad = res.data.category_ad;
                let targetIndex = $item.length > ad.position ? ad.position - 1 : $item.length;
                let adHtml = '<li class="prod-item ad-in-list">';
                if (!!ad.url) {
                    adHtml += '<a class="ad-wrap" href="' + ad.url + '" target="_blank">';
                }
                adHtml += '<img src="' + ad.img + '" class="prod-img">';
                if (!!ad.url) {
                    adHtml += '</a>';
                }
                adHtml += '</li>'
                let $ad = $(adHtml);
                $item.eq(targetIndex).after($ad);

            }
        }).catch(err=>{})
    },

    setViewImgModel() {
        const $btn = $('.title-tool .btn-view');
        const $prodList = $('.prod-list');
        let largerClass = 'larger';
        const cookieKey = 'viewImgLargerStatus'; //0:small;1:larger
        const cookieDays = 500;
        const curCookieVal = Cookies.get(cookieKey);

        $btn.on('click', function () {
            let cookieVal = 0;
            if ($btn.hasClass(largerClass)) {
                $btn.removeClass(largerClass).find('.text').text('view larger');
                $prodList.removeClass(largerClass);
            } else {
                $btn.addClass(largerClass).find('.text').text('view smaller');
                $prodList.addClass(largerClass);
                cookieVal = 1;
            }

            Cookies.set(cookieKey,cookieVal,{
                expires: cookieDays,
            })

            shopJS.report.monitor({
                category:'p-category',
                label: 'view Larger',
                action:'click',
                value:`to ${$btn.hasClass(largerClass) ? 'larger':'smaller'}`
            })
        })

        if(curCookieVal == 1 && $btn.length > 0){
            $btn.addClass(largerClass).find('.text').text('view smaller');
            $prodList.addClass(largerClass);
        }
    },

    getRecommendList() {
        let $listWarp = $('.recommend-list');
        let $list = $listWarp.find('.prod-list');
        let $loading = $listWarp.find('.loading-inline');
        let $listBottomTips = $('.list-bottom-tips');
        let winH = $(window).height();
        let offset = 800;
        let pid = $('input#pid').val();
        let page = {
            number: 1,
            pageSize: $list.data('page-size'),
        }
        let allowLoading = true;

        $(window).on('scroll', $u.throttle(this,handlerScroll,200));


        function handlerScroll() {
            let sData = getScrollData();
            let listBottom = sData.listTop + sData.listH;

            if (page.number > 5){
                allowLoading= false;
                return;
            }

            // console.log(sData,sData.winScrollTop+winH+offset,listBottom)

            if(sData.winScrollTop+winH+offset > listBottom && allowLoading) {
                allowLoading = false;
                $loading.removeClass('hide');

                $u.http.post('/api/products/you-may-also-like',{
                    pid: pid,
                    page: page.number,
                    pageSize: page.pageSize
                }).then(res=>{
                    // console.log(res);
                    if(res.data.code === 200 && res.data.data.length > 0) {
                        buildListDom(res.data.data)
                        page.number++;
                        if(res.data.data.length < page.pageSize) {
                            allowLoading = false;
                            $listBottomTips.removeClass('hide')
                        }else {
                            allowLoading = true;
                        }
                    }
                    $loading.addClass('hide');
                }).catch(err=>{
                    allowLoading = true;
                    $loading.addClass('hide');

                })
            }
        }

        function buildListDom(data) {
            let html = '';
            data.forEach((i)=>{
                let markStr = '';
                let marketPrice = '';
                if(i.mark_icon && i.mark_icon.length > 0) {
                    i.mark_icon.forEach((j)=>{
                        markStr+=`<div class="mark-item ${j}"></div>`
                    });
                }

                if(i.list_price){
                    marketPrice =`<div class="market-price notranslate">
                                    <div class="price-symbol">${i.currency_symbol}</div>
                                    <div class="price-value">${i.list_price}</div>
                                 </div>`
                }

                // Ship In ${i.stockHandleHours}hrs
                html += `
                    <li class="prod-item">
                        <a class="animate_click" href="${i.url}">
                            <div class="prod-img-wrap">
                                <img class="prod-img" src="${i.image}" decoding="async" loading="lazy" alt="${i.name}">
                                ${i.isInStock ? `<div class="mask-bottom">Ship In ${i.stockHandleHours}hrs</div>` : ''}
                            </div>
                        </a>
                        <div class="bottom-box">
                            <div class="price-box">
                                <div class="prod-price notranslate">
                                   <div class="price-symbol">${i.currency_symbol}</div>
                                    <div class="price-value">${i.price}</div>
                                </div>
                                ${marketPrice}
                           </div>
                            <div class="p-discount">
                                <div class="text">${i.discount}</div>
                                <div class="like-box">
                                    <div class="icon-s icon-like animate_click js-product-like ${i.isLiked ? 'liked' : ''}" data-pid="${i.pid}"></div>
                                    <div class="count">(${i.likes})</div>
                                </div>
                            </div>
                        </div>
                        <div class="mark-icon-box">${markStr}</div>
                    </li>`
            })

            $list.append(html);
        }

        function getScrollData() {
            return {
                listTop: $list.offset().top,
                listH:$list.height(),
                winScrollTop: $(window).scrollTop()
            }
        }
    }

};
