


shopJS.script={
    ajaxEmailSubscribe : function($this){
        $u.http.post('/api/widget/emailsubscribe/save',{
            email : $this.val()
        }).then(res=>{
            let result = res.data;
            shopJS.common.alert(result.data);
        }).catch()
    },

     emailSubscribe: function(){
        // shopJS.common.axSetup();

        const $emailSubscribe = $('.js-email-subscribe');
        $emailSubscribe.on('click', function(){
            let $emailSubscribeInput = $('.js-email-subscribe-input');
            if($emailSubscribeInput.easyVali('email')){
                shopJS.script.ajaxEmailSubscribe($emailSubscribeInput);
            }else{
                shopJS.common.alert('Please input correct email!');
            }
            return false;
        });
    }
};