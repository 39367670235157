shopJS.refer = {
    init: function () {
    },

    referalExtract: function () {
        // shopJS.common.axSetup();

        var $extractInput = $('.js-extract-input');
        $('.js-extract-btn').on('click', function (e) {
            e.preventDefault();
            var $extractMessage = $('.js-extract-message');
            if (!$extractInput.val().length) {
                $extractMessage.html('Please input exact amount!');
            } else {
                $extractMessage.empty();

                $u.http.post('/referal/ajax/extract-apply',{
                    amount: $extractInput.val()
                }).then(res=>{
                    let response = res.data;
                    if (response.code == 200 && response.data.success) {
                        $extractMessage.empty();
                        $('.refer-modal').modal('hide');
                        setTimeout(function () {
                            shopJS.common.alert('Request accepted. under reviewing for 2-4 days');
                        }, 500);
                    } else {
                        $extractMessage.html(response.data.message);
                    }
                })
            }
        });

        $('#alert').on('click', '.ok', function (e) {
            window.location.href = '/referal/dashboard';
        });
    },

    referalTransform: function () {
        // shopJS.common.axSetup();
        var $transformInput = $('.js-transform-input');
        var $transformMessage = $('.js-transform-message');
        var $transformPoint = $('.js-transform-point');
        $transformInput.on('keyup', function () {
            if (!$transformInput.val().length) {
                $transformPoint.html(0);
            } else {
                $u.http.post('/referal/ajax/transform-calculate',{
                    source: 'money',
                    target: 'point',
                    amount: $transformInput.val()
                }).then(res=>{
                    let response = res.data;
                    if (response.code == 200 && response.data.success) {
                        $transformMessage.empty();
                        $transformPoint.html(response.data.amount);
                    } else {
                        $transformMessage.html(response.data.message);
                    }
                })
            }
        });

        $('.js-transform-btn').on('click', function (e) {
            e.preventDefault();
            if (!$transformInput.val().length) {
                $transformMessage.html('Please input exact amount!');
            } else {
                $transformMessage.empty();
                $u.http.post('/referal/ajax/transform',{
                    source: 'point',
                    target: 'money',
                    amount: $transformInput.val()
                }).then(res=>{
                    let response = res.data;
                    if (response.code == 200 && response.data.success) {
                        $transformMessage.empty();
                        shopJS.common.alert('success!');
                    } else {
                        $transformMessage.html(response.data.message);
                    }
                })
            }

            $('#alert').on('click', '.ok', function (e) {
                window.location.href = '/referal/dashboard';
            });
        });

    },

    //copy link
    referralDashboard: function (el) {
        // shopJS.common.axSetup();

        var $btnCopy = $('.btn-copy');
        var $ipt = $btnCopy.siblings('input');
        $btnCopy.on('click', function (e) {
            e.preventDefault();
            $ipt.focus();
            $ipt[0].select();
            try {
                if (document.execCommand("Copy")) {
                    alert("You can now paste (Ctrl + v) and share the link!");
                    return
                }
            } catch (i) {
                //console.log(i);
            }
            alert("Your browser does not support this function, please select and copy, and then share it.")
        });
    },

    referSign: function () {
        var $form = $('.js-form-refer');
        if ($form.length) {
            $form.on('submit', function () {
                return $form.autoCheckForm({
                    last_name: 'required',
                    first_name: 'required',
                    email: 'required|email',
                    paypal: 'required',
                    paypal_confirmation: 'required|confirm:paypal'
                }).check();
            });
        }

        var $pasteDisable = $('.js-paste-disable');
        $pasteDisable.on('paste', function (e) {
            e.preventDefault();
        });
    },

    showFromShareModal() {
        const $modal = $('#fromShareModal');
        let urlParams = shopJS.common.getUrlParams();
        if (!urlParams.share_code) return;
        $u.http.post('/api/get-referral-coupon-info', {
            code: urlParams.share_code
        }).then((res) => {
            // console.log(res)
            if (res.data.code === 200 && res.data.data.show == true) {
                $modal.find('#referral_coupon').text(res.data.data.code);
                $modal.find('#coupon_benefit_value').text(res.data.data.coupon_benefit_value);
                $modal.find('.btn-copy').data('text', res.data.data.code);

                $modal.modal('show');
                shopJS.common.autoCopy('.btn-copy', '#fromShareModal .m-inner');
            }
        });
    },

    rewardInit() {
        shopJS.refer.wakeupFaceBookAPP();
        shopJS.refer.wakeupFaceBookMsgAPP();
        shopJS.refer.wakeupSMSAPP();
        shopJS.refer.sendShareEmail();
    },

    getShareLinkOption() {
        let fbAppid = $('input.fb_appid').val();
        let siteName = $('.site_name').val();
        let shareCode = $('.code-box .share-code').text();
        let hostUrl = window.location.protocol + "//" + window.location.hostname + "/";
        let originShareLink = `${hostUrl}referral/${shareCode}`

        return {
            fbAppID: fbAppid,
            siteName: siteName,
            shareCode: shareCode,
            originShareLink: originShareLink,
            shareLink: encodeURIComponent(originShareLink),
            fbRedirectUri: encodeURIComponent(originShareLink),
        }
    },

    wakeupFaceBookAPP() {
        let opt = shopJS.refer.getShareLinkOption();
        $('.btn-share.share-fb').on('click', () => {
            shopJS.report.monitor({label:'coupon-refer page',action:'facebook button'});
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${opt.shareLink}`, '', 'height=620,width=700,top=100,left=150');
        })
    },

    wakeupFaceBookMsgAPP() {
        let opt = shopJS.refer.getShareLinkOption();
        $('.btn-share.share-fbm').on('click', () => {
            shopJS.report.monitor({label:'coupon-refer page',action:'messenger button'});
            window.open(`fb-messenger://share/?link=${opt.shareLink}&app_id=${opt.fbAppID}`, "_self")
        })
    },

    wakeupSMSAPP() {
        const $btn = $('.btn-share.share-sms');
        let isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
        let opt = shopJS.refer.getShareLinkOption();
        let msg = `Hi Lovely, give you $15 Off for any bridal or party dress $79+ on ${opt.siteName}! I believe you'd like them. Here's my link: ${opt.originShareLink}`;

        $btn.on('click', () => {
            shopJS.report.monitor({label:'coupon-refer page',action:'sms button'});

            isIOS ? window.open("sms:&body=" + msg, "_self") : window.open("sms:?body=" + msg, "_self")
        })
    },

    sendShareEmail() {
        // let opt = shopJS.refer.getShareLinkOption();
        // let isLoading = false;
        $('.js-send-share-email').on('click', () => {
            let $email = $('.js-share-email-ipt');

            if (!$email.easyVali('email')) {
                alert('Please enter the correct Email Address!');
                return;
            }
            shopJS.report.monitor({label:'coupon-refer page',action:'email button'});
            shopJS.refer.sendShareEmailForFront();
            //
            // if (isLoading) return;
            //
            // isloading = true;
            // $u.http.post('/api/user/referral_coupon_email', {
            //     code: opt.shareCode,
            //     email: $email.val()
            // }).then((res) => {
            //     // console.log(res)
            //     if (res.data.code === 200) {
            //         alert('successfully.');
            //         $('.js-share-email-ipt').val('');
            //     } else {
            //         shopJS.refer.sendShareEmailForFront();
            //         // alert(res.data.data.message);
            //     }
            //     isLoading = false;
            // }).catch(() => {
            //        shopJS.refer.sendShareEmailForFront();
            //     // alert('Failed, please try again.');
            //     isLoading = false;
            // })
        })
    },

    sendShareEmailForFront() {
        let client = shopJS.common.getClientInfo();
        let opt = shopJS.refer.getShareLinkOption();
        let $email = $('.js-share-email-ipt');
        let subject = encodeURIComponent(`Hi Lovely, give you $15 OFF for your first purchase on ${opt.siteName}!`);
        var body;
        if (client === 'Safari 14'){
             body = encodeURIComponent("Hi Lovely, I found an amazing online fashion bridal & party dresses website. I believe you'd like them. Give you $15 Off for any order $79+. Click the link and enjoy your happy shopping now!" + opt.originShareLink + "Tips: When you open the link, please copy the coupon code so that you can use it at checkout. Let's shop, save & enjoy together! Sincerely");
        }else {
             body = encodeURIComponent("Hi Lovely,\n\nI found an amazing online fashion bridal & party dresses website. I believe you'd like them.\nGive you $15 Off for any order $79+. Click the link and enjoy your happy shopping now!\n" + opt.originShareLink + "\n\nTips: When you open the link, please copy the coupon code so that you can use it at checkout.\nLet's shop, save & enjoy together!\n\nSincerely\n\n\n");
        }

        document.location.href = "mailto:" + $email.val() + "?subject=" + subject + "&body=" + body
    }
}
;
