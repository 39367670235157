shopJS.orderTrack = {
    init: function () {
        shopJS.orderTrack.ajaxOrderCoupon();
        shopJS.orderTrack.orderCouponChose();
        shopJS.orderTrack.initTrackingInfoModal();
        shopJS.cart.cartItemDetail();
    },
    orderCouponChose: function () {
        setTimeout(function () {
            let $orderCouponInput = $('.js-order-coupon');
            if ($orderCouponInput && !$orderCouponInput.is(':checked')) {
                $orderCouponInput.trigger('click');
            }
        }, 10);
    },
    ajaxOrderCoupon: function () {
        let $orderCouponInput = $('.js-order-coupon');
        let $msg = $('.js-order-coupon-msg');
        $orderCouponInput.click(function () {
            let data = {};

            data.oid = $orderCouponInput.data('oid');
            data.choose = $orderCouponInput.is(':checked');
            $u.http.post('/order/couponCheck', data).then(res => {
                let result = res.data;
                if (result.code == 200) {
                    $msg.text('');
                    shopJS.orderTrack.orderCouponDataToDocument(result.data);
                } else {
                    $msg.text(result.data.message);
                    $orderCouponInput.attr('checked', !chose);
                }
            }).catch(err => {
            })
        })
    },
    orderCouponDataToDocument: function (data) {
        let $payAmount = data.payAmount_c;
        let $subTotalAmount = data.totalAmount_c;
        let $subTotalQtyItems = data.subQtyItems;
        let $subTotalList = data.subList;
        let $subTotalFees = data.subFees;
        let $subTotalBenefits = data.subBenefits;

        let $orderSummaryPayAmount = $('.js-order-summary-total-price');
        let $subBox = $('.js-order-subtotal-box');

        $orderSummaryPayAmount.text($payAmount);
        let subTotalBoxHtml = '';
        subTotalBoxHtml += shopJS.orderTrack.orderSubtotalCommonListHtml('', ('Subtotal (' + $subTotalQtyItems + '):'), '', $subTotalAmount, 'subtotal');
        if ($subTotalList) {
            $($subTotalList).each(function (k, v) {
                subTotalBoxHtml += shopJS.orderTrack.orderSubtotalCommonListHtml('', (v.key + ':'), '', v.value_c, '');
            })
        }
        subTotalBoxHtml += shopJS.orderTrack.orderSubtotalCommonListHtml('', 'Grand Total:', '', $payAmount, 'grandtotal');
        $subBox.html(subTotalBoxHtml);
    },
    orderSubtotalCommonListHtml: function (pClass, subL, subLClass, subR, subRClass) {
        return '<li>' +
            '<span class="pull-left ' + subLClass + '">' + subL.firstUpperCase() + '</span>' +
            '<span class="pull-right ' + subRClass + '">' + subR + '</span>' +
            '</li>';
    },

    initTrackingInfoModal() {
        shopJS.orderTrack.bindTrackingInfoBtn();
        shopJS.orderTrack.getTrackingInfo();
    },

    bindTrackingInfoBtn() {
        const $btn = $('.btnShowTrackingModal');
        const $modal = $('#tracking-modal');

        $btn.on('click', function () {
            $modal.modal('show');
        })

    },

    getTrackingInfo() {
        const $btn = $('.btnShowTrackingModal');
        const $trackingButtonItem = $('.trackingButtonItem');
        const token = $btn.data('order-token');

        const $modal = $('#tracking-modal');
        const $summary = $modal.find('.summary');
        const $list = $modal.find('.list');

        $u.http.get(`/order/shipping/tracking?token=${token}`).then(res => {
            console.log(res);
            if (res.data.code === 200) {
                let data = res.data.data;
                let summaryHtml = `
                    <div class="item i-exp">
                        <div class="key">Shipping Company:</div>
                        <div class="val">${data.express}</div>
                    </div>
                    <div class="item i-expNum">
                        <div class="key">Shipping Number:</div>
                        <div class="val">${data.express_number}</div>
                    </div>
                    <div class="item i-status">
                        <div class="key">Status:</div>
                        <div class="val">${data.status}</div>
                    </div>
                `;
                $summary.html(summaryHtml).removeClass('hide');
                $trackingButtonItem.removeClass('hide')

                let logs = data.tracking_logs;
                if (logs.length > 0) {
                    let listHtml = ``;
                    for (let i = 0, len = logs.length; i < len; i++) {
                        listHtml += `
                        <div class="item">
                            <div class="left"></div>
                            <div class="right">
                                <div class="status">${logs[i].status}</div>
                                <div class="address">${logs[i].address}</div>
                                <div class="detail">${logs[i].detail}</div>
                                <div class="time">${logs[i].time}</div>
                            </div>
                        </div>
                        `;
                    }
                    $list.html(listHtml).removeClass('hide');
                } else {
                    $list.addClass('hide')
                }
            } else {
                $trackingButtonItem.addClass('hide')
            }
        }).catch(err => {
            console.log(err);
            $trackingButtonItem.addClass('hide')
        })
    }
};