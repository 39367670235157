


shopJS.form = {
    orderStatusFormCheck : function(){
        $('.js-order-status-form').on('submit', function(e){
            return $(this).autoCheckForm({
                order_number : 'required',
                delivery_email : 'required|email'
            }).check();
        });
    }
};
