
/*
* 任一事件的相关参数统一传入report，report再向各个上报系统分发。
*
* */

shopJS.report = {
    init() { },

    viewTerm(data){
        $u.analytics.track('view_term',data)
    },

    selectItem(data) {
        shopJS.ga4.selectItem({
            termID:     data.termID,
            termName:   data.termName,
            products:   data.products,
        });

        $u.analytics.track('select_item',{
            'item_list_id':     data.termID,
            'item_list_name':   data.termName,
            'items':            data.products,
        })
    },

    addToWishList(data) {
        shopJS.ga4.addToWishList(data.products, data.value, data.currency);

        $u.analytics.track('add_to_wishlist',{
            'currency':         data.currency,
            'value':            data.value,
            'items':            data.products
        })
    },

    detail(products=[], list=''){
        shopJS.ga.detail(products);
        shopJS.ga4.detail(products);
        shopJS.pin.detail(products);
        $u.analytics.track('view_item',{
            'items':    products
        })
    },

    addToCart(products=[]){
        shopJS.ga.addToCart(products);
        shopJS.ga4.addToCart(products);
        shopJS.pin.addToCart(products);
        shopJS.bing.addToCart(products);
        $u.analytics.track('add_to_cart',{
            'items':    products
        })
    },

    removeFormCart(products=[]){
        shopJS.ga.removeFormCart(products);
        $u.analytics.track('remove_form_cart',{
            'items':    products
        })
    },

    checkoutEnum: {
        1: 'save address',
        2: 'shipping method',
        3: 'select rush',
        4: 'use coupon',
        5: 'place order',
        6: 'payment method',
        7: 'payment'
    },

    checkout(data) {
        let stepString = shopJS.report.checkoutEnum[data.step];
        shopJS.ga.checkout(data.step,stepString);
        $u.analytics.track('checkout',{
            action: stepString
        })
    },

    purchase(data) {
        // console.log('report purchase');
        shopJS.ga.purchase(data.action,data.products);
        shopJS.ga4.purchase(data.products,data.value,data.orderNumber,data.currency);
        shopJS.pin.checkout(data.value,data.currency,data.orderNumber,data.email,data.products);
        shopJS.bing.purchase(data.products,data.value,data.orderNumber);
        $u.analytics.track('purchase',{
            'currency':         data.currency,
            'transaction_id':   data.orderNumber,
            'value':            data.value,
            'items':            data.products
        })
    },

    monitor(data,callback=null) {
        shopJS.ga.monitor(data,callback)
        $u.analytics.track('monitor',data)
    },

};