shopJS.payment = {
    init: function () {
        shopJS.payment.initPaymentMethods();

        // facebook pixel
        if (shopJS.facebook.status) {
            shopJS.facebook.addPaymentInfo();
        }

        // facebook pixel
        shopJS.salesTracker.beforePaymentSalesTracker();
    },

    initPaymentMethods: function () {
        // shopJS.common.loadingModal('show');
        let paymentMethodInputs = $("input[name='choice-payment']");
        let paymentMethodContentDD = $('.payment-list').find("dd");
        let orderNumber = $("input[name='js-order-number']").val();

        // 对于 payment list 中的支付方式，绑定 input 切换
        paymentMethodInputs.on('click', function () {
            let paymentMethod = $(this).data('pay-method');
            let paymentChannel = $(this).data('pay-channel');
            let container = $(this).parents('dl');
            let thisContentBox = container.find("dd");

            // 已经点击了
            if ($(this).hasClass('on')) {
                return;
            }

            // UI 变化
            paymentMethodInputs.each(function(){
                $(this).removeClass('on');
            });

            paymentMethodContentDD.each(function () {
                $(this).addClass("hide");
            });

            $(this).addClass('on');
            thisContentBox.removeClass('hide');

            // 通知服务器，切换支付方式
            $u.http.post('/api/payment/changePaymentMethod', {
                number:          orderNumber,
                payment_method:  paymentMethod,
                payment_channel: paymentChannel
            }).then(res => {
                // console.log(res)
            }).catch(err => {
                // console.log(err)
            })

            // 统计分析
            shopJS.report.checkout({step: 6});
        })


        // all payment method init
        $("input[name='choice-payment']").each(function (index, element) {
            let paymentMethod = $(this).data('pay-method');
            let paymentChannel = $(this).data('pay-channel');
            // console.log(paymentChannel)
            // console.log(paymentMethod)

            if (paymentMethod === 'creditcard' && paymentChannel === 'asiabill') {
                shopJS.payment_asiabill_credit_card.init();
            }

            if (paymentMethod === 'afterpay' && paymentChannel === 'asiabill') {
                shopJS.payment_afterpay.init();
            }

            if (paymentMethod === 'klarna' && paymentChannel === 'asiabill') {
                shopJS.payment_klarna.init();
            }

            if (paymentMethod === 'paypal-rest' && paymentChannel === 'paypal') {
                shopJS.payment_paypal_rest.init();
            }

            if(paymentMethod === 'paypal' && paymentChannel === 'paypal'){
                shopJS.payment_paypal.init();
            }
        })

        // $(".js-ab-payment input").trigger('click');
    },

    /*
    * 点支付按钮时设置来源标记，在支付回调页检查标记，如存在则判定为有效支付行为，同时清除标记。
    * @param  {type} string   'set' | 'check' | undefined
    * @return boolean | undefined
    * */
    checkPaymentFrom(type = 'check') {
        let cookieKey = 'effectivePayment';
        let cookieVal = '1';
        let cookieDays = 0.5 / 24; // half hour

        if (type === 'set') {
            Cookies.set(cookieKey, cookieVal, {
                expires: cookieDays,
            })
        } else {
            let val = Cookies.get(cookieKey);
            Cookies.remove(cookieKey);
            return val === cookieVal
        }
    },

};