// paypal rest payment.

shopJS.payment_paypal = {

    init() {
        shopJS.payment_paypal.initPayment()
    },

    initPayment: function () {
        let submitButton = $(".js-btn-payment-paypal");
        submitButton.on("click", function () {
            let $errBox = $(this).prev(".payment-message");

            shopJS.payment_paypal.checkOrderStatus().then(res => {
                if(res === false){
                    $errBox.text('This order has been paid, please do not pay again').removeClass('hide');
                    return;
                }

                shopJS.payment.checkPaymentFrom('set');
                shopJS.report.checkout({step: 7});
                $errBox.text('').hide();

                $('#loadingModal').modal('show');
                $('.paypal-form form').submit();
            })
        })
    },

    checkOrderStatus: function() {
        return $u.http.post('/api/payment/checkOrderPaymentStatus', {
            number: $('.js-order-number').val()
        }).then(function (res) {
            return res.data.code === 200 && res.data.data.success === true;
        }).catch(function (err) {
            return true;
        });
    },
};