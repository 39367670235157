

shopJS.filter = {
    init: function () {
        const $filter = $('.js-filter-modal');
        const $selected = $('.js-filter-selected');
        const $filterOption = $('.js-filter-option');
        const $filterValues  = $('.js-filter-values');

        if($filterValues.length && $filterValues.val() != '[]'){
            shopJS.filter.selected = JSON.parse($filterValues.val());
            shopJS.filter.originSelected = JSON.parse($filterValues.val());
        }

        $filter.on('click', '.js-filter-option', function(e){
            e.preventDefault();
            const $this = $(this);
            let key = $this.data('key');
            let value = $this.data('value');
            let caption = $.trim($('.text',this).text());
            const $selectedItem = $selected.find('li div[data-value="'+value+'"]').closest('li');
            if($this.hasClass('selected')){
                $this.removeClass('selected');
                $selectedItem.remove();
                shopJS.filter.removeSelected(key, value);
            }else{
                const $filterField = $this.closest('.item1');
                if($filterField.data('type') == 'single'){
                    const $filterOption =  $filterField.find('.js-filter-option');
                    $filterOption.removeClass('selected');
                    $this.addClass('selected');
                    shopJS.filter.addSelected(key, value);

                    $.each($filterOption, function(index, element){
                        const $elemnet = $(element);
                        if(!$elemnet.hasClass('selected')){
                            shopJS.filter.removeSelected($elemnet.data('key'), $elemnet.data('value'));
                            $selected.find('li div[data-value="'+ $elemnet.data('value')+'"]').closest('li').remove();
                        }
                    });
                }

                if($selectedItem.length == 0){
                    let content = '<li>';
                    content += '<div class="btn-close icon-s js-filter-clear" data-key="'+value+'" data-value="'+value+'" data-location="modal"></div>';
                    content += '<div>'+caption+'</div>';
                    content += '</li>';
                    $selected.append($(content));
                }
                $this.addClass('selected');
                shopJS.filter.addSelected(key, value);
            }
        });

        $filter.on('click', '.js-filter-done', function(e){
            e.preventDefault();
            shopJS.filter.resetSelected();
        });

        $filter.on('click', '.js-filter-clear', function(e){
            e.preventDefault();
            const $this = $(this);
            let value = $this.data('value');
            let key = $this.data('key');
            if(!value || !key){
                shopJS.filter.selected = {};
                $selected.empty();
                $filterOption.removeClass('selected');
            }else{
                shopJS.filter.removeSelected(key, value);
                $filter.find('.js-filter-option[data-value="'+value+'"]').removeClass('selected');

                $selected.find('li div[data-value="'+value+'"]').closest('li').remove();
            }
        });

    },

    selected : {},

    //保存url中所带筛选项
    originSelected:[],

    removeSelected : function(key, value){
        key = String(key);
        value = String(value);
        if(shopJS.filter.selected[key]){
            let index = shopJS.filter.selected[key].indexOf(value);
            if(index > -1){
                shopJS.filter.selected[key].remove(value);
            }

            //删除所有空项
            if(shopJS.filter.selected[key].length < 1) {
                delete shopJS.filter.selected[key];
            }
        }
    },

    addSelected : function(key, value){
        if(shopJS.filter.selected[key] == undefined){
            shopJS.filter.selected[key] = [];
        }
        if(value && Object.prototype.toString.call(value) !== "[object String]"){
            value = String(value);
        }
        if(shopJS.filter.selected[key].indexOf(value) === -1){
            shopJS.filter.selected[key].push(value);
        }
    },

    resetSelected : function () {
        let searchParamList = shopJS.filter.recoverFilterOption(shopJS.filter.baseName(window.location.pathname)).split(/[\\/]/);
        let filterUrl = window.location.protocol+"//" + window.location.hostname + "/" + searchParamList[1] + "/";

        let selectParam = 'all';
        let delimiter = '--';
        let selected = shopJS.filter.selected;
        if(JSON.stringify(selected) != '{}'){
            selectParam = '';
            for(let key in selected){
                if( selected[key].length > 0){
                    let values = selected[key].map(function(value){
                        return String(value).replace(/\-/g, "&#45;")
                                            .replace(/\\/g, "&#92;")
                                            .replace("/", "&#47;");
                    }).map(function(value) {
                        return value.replace(/&#45;/g, "-dash-")
                                    .replace(/&#92;/g, "-backslash-")
                                    .replace(/&#47;/g, "-slash-");
                    });

                    selectParam += key+delimiter+values.join(',')+delimiter;
                }
            }
            if(selectParam.length == 0){
                selectParam = 'all';
            }else{
                selectParam = selectParam.substr(0, selectParam.length -2);
            }
        }

        //选中的项id增加到s参数中。
        let selectKeys = Object.keys(shopJS.filter.selected);

        //遍历原始项。如值被清除，则增加到s参数中，如值未改动，则从s参数中删除此项id。
        for(let key in shopJS.filter.originSelected) {
            if(!shopJS.filter.selected[key]) {
                selectKeys.push(key);
            }else if(shopJS.filter.originSelected[key].sort().toString() === shopJS.filter.selected[key].sort().toString()){
                selectKeys.remove(key)
            }
        }

        let sValue = selectKeys.length>0? selectKeys.join(','):'';
        // console.log(sValue);
        // console.log(`${filterUrl}${selectParam}/${sValue.length>0? `?s=${sValue}`:'' }`);
        window.location.href = `${filterUrl}${selectParam}/${sValue.length>0? `?s=${sValue}`:'' }`;

        /*Closing action conflicts with poster popup, cancel closed*/
        // $('#filter-modal').on('hidden.bs.modal',shopJS.common.loadingMask);
        // $('#filter-modal').modal('hide');
    },

    baseName : function(str) {
        let index = str.lastIndexOf('.');
        if (index != -1) {
            return str.substring(0, index);
        }
        return str;
    },

    recoverFilterOption : function(value) {
        return value.replace(/\-dash\-/g, "&#45;").replace(/\-backslash\-/g, "&#92;").replace(/\-slash\-/g, "&#47;");
    },

    //select filter
    selectFilter: function () {
        const $itemLevel1 = $('#filter-modal .item1');
        const $listLevel2 = $('#filter-modal .flt-2');
        const $filterVal = $('#filter-modal .f-value');
        const $btnClear = $('#filter-modal .clear-all');

        //open list.
        $('.f-title').on('click',function (e) {
            const $curFilter = $(this).parent();
            let flag = !$curFilter.hasClass('open');

            e.preventDefault();

            $itemLevel1.removeClass('open');
            if(flag) {
                $curFilter.addClass('open');
            }
        });

        //add filter
        $filterVal.on('click',function (e) {
            e.preventDefault();
            const $this = $(this);
            let html;

            if($this.hasClass('selected')) {
                const $filter=$('.filter-box [data-value="'+$this.data('value')+'"]');
                shopJS.filter.rmFilter($filter);
            }else {
                html='<li data-value="' +
                    $this.data('value') +'">' +
                    '<span>'+$this.data('value')+'</span>' +
                    '<span class="btn-close">X</span>' +
                    '</li>';
                $(html).prependTo('#filter-modal .filter-box ul');
                $this.addClass('selected');
            }
        });

        //clear all
        $btnClear.on('click',function (e) {
            e.preventDefault();

            $filterVal.filter('.selected').each(function () {
                const $this=$(this);
                const $filter=$('.filter-box [data-value="'+$this.data('value')+'"]');
                shopJS.filter.rmFilter($filter);
            });

            $itemLevel1.removeClass('open');
        })
    }

    //remove filter event
    ,removeFilterEvent: function (el) {
        $(el).on('click','.btn-close',function () {
            const $filter=$(this).parents('li');
            shopJS.filter.rmFilter($filter);
        });
    }

    //remove filter function
    ,rmFilter: function ($filter) {
        if($filter.parentsUntil('#filter-modal').length>0) {
            const $radio=$('.f-value[data-value="'+$filter.data('value')+'"]');
            $radio.removeClass('selected');
        }
        $filter.remove();
    }
};
