shopJS.question = {
    init: function () {
        shopJS.question.modalInit();
        shopJS.question.submitQuestion();
        shopJS.question.questionHelpful();
    },

    modalInit() {
        let $questionModal = $('#submit-a-question');
        // if user has already login, auto fill user email
        $questionModal.on('shown.bs.modal', function () {
            let userName = shopJS.common.getUserName();
            let $email = $('#question-email');
            if (userName) {
                $email.val(userName);
            }
        })
    },

    submitQuestion() {
        $(".btn-question-submit").on("click", function (e) {
            e.preventDefault();

            let $questionModal = $('#submit-a-question');
            let $errorInfo = $('#submit-a-question .is-error');
            let $successInfo = $('#submit-a-question .success-info');
            let $successInfoSpan = $('#submit-a-question .success-info span');
            let $name = $('#question-name');
            let $email = $('#question-email');
            let $content = $('#question-content');
            let pid = $('#pid').val();

            if ($name.val() === '') {
                $errorInfo.text('Please enter your name.').removeClass('hide');
                return;
            } else {
                $errorInfo.text('').addClass('hide');
            }

            if ($email.val() === '') {
                $errorInfo.text('Please enter your email.').removeClass('hide');
                return;
            } else if (!$email.easyVali('email')) {
                $errorInfo.text('Please input correct email.').removeClass('hide');
                return;
            } else {
                $errorInfo.text('').addClass('hide');
            }

            if ($content.val() === '') {
                $errorInfo.text('Please enter your question.').removeClass('hide');
                return;
            } else {
                $errorInfo.text('').addClass('hide');
            }

            $u.http.post('/api/product/questions', {
                pid:      pid,
                name:     $name.val(),
                email:    $email.val(),
                question: $content.val(),
            }).then(res => {
                let result = res.data;
                console.log(result);
                if (result.code === 200) {
                    $successInfo.removeClass('hide');
                    let timer = 3;
                    let closeTimer = setInterval(_ => {
                        if (timer < 0) {
                            clearInterval(closeTimer);
                            $content.val('');
                            $email.val('');
                            $name.val('')
                            $questionModal.modal('hide');
                            $successInfo.addClass('hide');
                        } else {
                            $successInfoSpan.text(timer);
                            timer--
                        }
                    }, 1000)

                }
            }).catch(err => {
                let res = err.response;
                console.log(res);
                if (res.status === 401) {
                    window.location = '/login';
                }
            })
        })
    },

    questionHelpful() {
        $('.js-helpful').on('click', function (e) {
            let url = '/api/product/questions/helpful';
            let pid = $(this).data('pid');
            let questionId = $(this).data('question-id');
            let helpfulCount = $(this).parent().find('.helpful-count');

            let data = {
                pid:         pid,
                question_id: questionId
            }
            $u.http.post(url, data).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    let helpful = data.data.helpful;
                    let message = data.data.message;
                    if (message) {
                        shopJS.common.alert(message);
                    }
                    helpfulCount.html("(" + helpful + ")")
                } else {
                    shopJS.common.alert('Failed,Please try again');
                }

            }).catch((err) => {
                let res = err.response;
                console.log(res);
                shopJS.common.alert('Failed,Please try again');
            })
        });
    }
};
