


shopJS.header = {
    init() {
        shopJS.header.navListelastic();
        shopJS.header.showSearchBox();
        shopJS.header.showHeader();
    },
    navListelastic () {
        var $leve1 = $('.leve1');
        var $leve2 = $('.leve2');
        var $leve3Title = $('.leve3-title');
        var $backPrev = $('.back-prev');
        $leve2.css('display','none')
        $leve1.on('click',function () {
            if($(this).siblings('.leve2').is(':hidden')){
                $(this).siblings('.leve2').slideDown(400)
                    .parents('.nav-parent')
                    .siblings('.nav-parent')
                    .children('.leve2').slideUp(200);

                $(this).children('i').css('transform','rotate(-180deg)')
                    .parents('.nav-parent')
                    .siblings('.nav-parent')
                    .find("i").css('transform','rotate(0)')
            }else{
                $(this).siblings('.leve2').slideUp(200);
                $(this).children('i').css('transform','rotate(0)')
            }
        });
        $leve3Title.on('click',function () {
            $(this).addClass('hide')
                .parents()
                .parents()
                .siblings('.leve1')
                .addClass('hide')
                .parents('.nav-parent')
                .siblings('.nav-parent')
                .addClass('hide');
            $(this).siblings('.leve3')
                .removeClass('hide')
                .parents()
                .siblings('.nav-child')
                .addClass('hide');
        });
        $backPrev.on('click',function () {
            $(this).parents()
                .siblings('.leve3-title')
                .removeClass('hide')
                .parents()
                .parents()
                .siblings('.leve1')
                .removeClass('hide')
                .parents('.nav-parent')
                .siblings('.nav-parent')
                .removeClass('hide');
            $(this)
                .parents('.leve3')
                .addClass('hide')
                .parents()
                .siblings('.nav-child')
                .removeClass('hide');
        });
    },

    //search box display
    showSearchBox () {
        var $headerInner = $('header .inner');
        var $btnSearch = $('.inner .btn-search');
        var $searchBox = $('header .search-box');

        var $btnClose = $('.search-box .btn-close');
        var $searchInput = $('.search-box input');

        $btnSearch.on('click',function (e) {
            e.preventDefault();
            $headerInner.addClass('hide');
            $searchBox.removeClass('hide');
            $searchInput.focus();
            setTimeout(function(){
                $searchInput[0].setSelectionRange(-1,-1);
            },10);
        });

        $btnClose.on('click',function (e) {
            e.preventDefault();
            if($searchInput.val() === '') {
                $headerInner.removeClass('hide');
                $searchBox.addClass('hide');
            } else {
                $searchInput.val('').focus();
                setTimeout(function(){
                    $searchInput[0].setSelectionRange(-1,-1);
                },10);
            }
        });

        $searchBox.on('click', '.btn-search-submit', function(e){
            search();
        });

        $searchInput.on('keypress', function(e){
            if(e.keyCode == 13){
                search();
            }
        });

        // text auto complete
        $searchInput.textcomplete([
            {
                id: 'dress-list',
                match: /\b(.+)$/,
                search: function (term, callback) {

                    if(term.length<1) return;

                    term = term.toLowerCase();
                    $u.http.post('/api/search/complete',{
                        keywords : term
                    }).then(res=>{
                        let result = res.data;
                        if(result.code == 200){
                            let words=[];
                            $.each(result.data, function(key, value){
                                words.push(value.name);
                            });
                            callback(words);
                        }
                    }).catch(err=>{
                        callback([]);
                        console.log(err);
                    })
                },
                index: 1,
                replace: function (word) {
                    return word + ' ';
                }
            }
        ],{
            className:'search-complete-dropdown',
            debounce:200,
            maxCount:15,
            zIndex:1001
        }).on({
            'textComplete:select': function (e, value, strategy) {
                search();
            }
        });

        function search(){
            var keyword = $searchInput.val().trim();
            if(keyword !== ''){
                //facebook pixel
                if (shopJS.facebook.status) {
                    shopJS.facebook.search(keyword)
                }
                keyword = keyword.replace('/','^');
                window.location.href = window.location.protocol+"//" + window.location.hostname + "/search/" + keyword + "/";
            }
        };
    },

    //header show
    showHeader () {
        const $header = $('header');
        const $placeHolder = $('.pl-box');
        let isfixedHeader = $header.hasClass('fixedbar');
        let scrollStart = 0, scrollEnd = 0;

        if ($('.p-vip header').length>0) {
            return
        }

        $(window).on('scroll',$u.throttle(this, handlerScroll, 100));

        function handlerScroll () {
            let scroll = $(window).scrollTop();
            if(!isfixedHeader && scroll>200) {
                $header.addClass('fixedbar');
                $placeHolder.addClass('show');
                isfixedHeader = true;
            }
            if(isfixedHeader && scroll<200){
                $header.removeClass('fixedbar').removeClass('in');
                $placeHolder.removeClass('show');
                isfixedHeader = false;
                if($('.p-prod').length > 0) {
                    shopJS.prod.initPositioningBar({exec:'scroll'});
                }

                if($('.p-idx .recommend-product').length > 0) {
                    shopJS.index.setrpHeaderTop();
                }
            }

        }

        $(window).on('touchstart',function () {
            scrollStart = $(window).scrollTop();
        });
        $(window).on('touchend',function () {
            let delta;
            scrollEnd = $(window).scrollTop();
            delta = scrollStart-scrollEnd;
            if(isfixedHeader && scrollEnd > 800){
                if(delta>50) {
                    $header.addClass('in');
                }else if(delta<0) {
                    $header.removeClass('in');
                }
            }

            if($('.p-prod').length > 0) {
                shopJS.prod.initPositioningBar({exec:'scroll'});
            }

            if($('.p-idx .recommend-product').length > 0) {
                shopJS.index.setrpHeaderTop();
            }
        });
    }


};
