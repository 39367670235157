shopJS.salesTracker = {

    paymentSucceededSalesTracker : function () {
        var $orderPaymentSucceededSalesTracker = $('.js-payment-succeeded-sales-tracker');
        if ($orderPaymentSucceededSalesTracker.length > 0) {
            window.payment_succeeded_custom_variables = this.getDynamicVariables($orderPaymentSucceededSalesTracker);
        }
    }

    , beforePaymentSalesTracker : function () {
        var $orderPaymentBeforeSalesTracker = $('.js-payment-before-sales-tracker');
        if ($orderPaymentBeforeSalesTracker.length > 0) {
            window.before_payment_custom_variables = this.getDynamicVariables($orderPaymentBeforeSalesTracker);
        }
    }

    , getDynamicVariables : function (salesTracker) {
        return [
            {name: '__order_id', value: salesTracker.data('number')},
            {name: '__order_price', value: salesTracker.data('total')}
        ];
    }
};