// paypal rest payment.

shopJS.payment_paypal_rest = {

    init() {
        const $btnBox = $('#paypal-rest-button-container .inner')
        if ($btnBox.length < 1) {
            return
        }
        shopJS.payment_paypal_rest.initPayment();
    },

    initPayment() {
        const orderNumber = $('.js-order-number').val();
        const $errBox = $('#paypal-rest-button-container .error-box');
        // Render the PayPal button into #paypal-button-container
        paypal.Buttons({
            // Call your server to set up the transaction
            createOrder(data, actions) {
                shopJS.payment.checkPaymentFrom('set');
                shopJS.report.checkout({step: 7});

                $errBox.text('').addClass('hide');

                return $u.http.post('/api/paypal/order/create', {
                    orderNumber: orderNumber,
                }).then(function (res) {
                    let orderData = res.data;
                    // console.log(orderData)
                    if(orderData.status === 'FAILED') {
                        let msg = 'Sorry, your transaction could not be processed.';
                        $errBox.text(msg).removeClass('hide');
                        return null
                    }else {
                        return orderData.id;
                    }
                });
            },

            // Call your server to finalize the transaction
            onApprove(data, actions) {
                return $u.http.post('/api/paypal/order/capture', {
                    orderId: data.orderID,
                }).then(function (res) {
                    console.log(res);
                    console.log(data);
                    console.log(actions);
                    let orderData = res.data;
                    // Three cases to handle:
                    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    //   (2) Other non-recoverable errors -> Show a failure message
                    //   (3) Successful transaction -> Show confirmation or thank you

                    let errorDetail = Array.isArray(orderData.details) && orderData.details[0];

                    if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                        return actions.restart(); 
                    }

                    if (errorDetail) {
                        var msg = 'Sorry, your transaction could not be processed.';
                        // if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                        // if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                        $errBox.text(msg).removeClass('hide');
                        return
                    }

                    // Successful capture! For demo purposes:
                    console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                    return actions.redirect(orderData.redirect_url);
                });
            }

        }).render('#paypal-rest-button-container .inner');
    }

};