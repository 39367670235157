

shopJS.ckAddress = {
    addressInput : {
        rid : $('#rid'),
        deliveryFirstName : $('#first-name'),
        deliveryLastName : $('#last-name'),
        deliveryAddress : $('#address'),
        deliveryCity : $('#city'),
        deliveryCid : $('#country-id'),
        deliveryPid : $('#province-id'),
        deliveryProvince : $('#province'),
        deliveryPostcode : $('#postal'),
        deliveryMobile : $('#phone'),
        deliveryEmail : $('#email'),
        newUserAdd : $('#c-account'),
        password : $('#password'),
        passwordConfirmation : $('#confirm-ps')
    },

    addressList :  $('.js-address-list'),

    addressSelected : $('.js-address-selected'),
    provinces: [],

    address : null,

    getAddressInputVal : function(){
        var address = {
            rid : $.trim(shopJS.ckAddress.addressInput.rid.val()),
            delivery_first_name : $.trim(shopJS.ckAddress.addressInput.deliveryFirstName.val()),
            delivery_last_name : $.trim(shopJS.ckAddress.addressInput.deliveryLastName.val()),
            delivery_address : $.trim(shopJS.ckAddress.addressInput.deliveryAddress.val()),
            delivery_city : $.trim(shopJS.ckAddress.addressInput.deliveryCity.val()),
            delivery_cid : shopJS.ckAddress.addressInput.deliveryCid.val(),
            delivery_pid : shopJS.ckAddress.addressInput.deliveryPid.hasClass('hide') ? 0 : shopJS.ckAddress.addressInput.deliveryPid.val(),
            delivery_province : shopJS.ckAddress.addressInput.deliveryProvince.hasClass('hide') ? '' : $.trim(shopJS.ckAddress.addressInput.deliveryProvince.val()),
            delivery_postcode : $.trim(shopJS.ckAddress.addressInput.deliveryPostcode.val()),
            delivery_mobile : $.trim(shopJS.ckAddress.addressInput.deliveryMobile.val()),
            delivery_email : $.trim(shopJS.ckAddress.addressInput.deliveryEmail.val())
        };

        if(shopJS.ckAddress.addressInput.newUserAdd.length > 0 && shopJS.ckAddress.addressInput.newUserAdd.is(':checked')){
            address.new_user_add = 1;
            address.password = $.trim(shopJS.ckAddress.addressInput.password.val());
            address.password_confirmation = $.trim(shopJS.ckAddress.addressInput.passwordConfirmation.val());
        }
        return address;
    },

    setAddressInput : function(address){
        let addrInput = shopJS.ckAddress.addressInput;
        addrInput.rid.val(address && address.rid ? address.rid : 0);
        addrInput.deliveryFirstName.val(address && address.delivery_first_name ? address.delivery_first_name : '');
        addrInput.deliveryLastName.val(address && address.delivery_last_name ? address.delivery_last_name : '');
        addrInput.deliveryAddress.val(address && address.delivery_address ? address.delivery_address : '');
        addrInput.deliveryCity.val(address && address.delivery_city ? address.delivery_city : '');
        addrInput.deliveryPostcode.val(address && address.delivery_postcode ? address.delivery_postcode : '');
        addrInput.deliveryMobile.val(address && address.delivery_mobile ? address.delivery_mobile : '');
        addrInput.deliveryEmail.val(address && address.delivery_email ? address.delivery_email : '');
        addrInput.password.val(address && address.password ? address.password : '');
        addrInput.passwordConfirmation.val(address && address.password_confirmation ? address.password_confirmation : '');

        let cid = address && address.delivery_cid ? address.delivery_cid : 1;
        let pid = address && address.delivery_pid ? address.delivery_pid : null;
        let province = address && address.delivery_province ? address.delivery_province : '';
        addrInput.deliveryCid.val(cid);
        /* update country && province */
        shopJS.ckAddress.ajaxCountryChange($('.js-country-list'),()=>{
            if(province) {
                $('.js-address-form #province').val(province);
            }
            if(pid) {
                $('.js-address-form #province-id').val(pid);
            }
        });

    },

    setSelectedAddress : function($address){
        $address.find('.js-address-edit').closest('li').remove();
        shopJS.ckAddress.addressSelected.empty().append($address).removeClass('hide');
        // address List Hide
        shopJS.ckAddress.addressList.addClass('hide');
        $('.js-new-address').addClass('hide');

        shopJS.ckAddress.setAddress($address.data('addressjson'));
    },

    addressInputCheck : function(){
        var $addressForm =  $('.js-address-form');
        var address = shopJS.ckAddress.getAddressInputVal();
        var checkSet = {
            first_name : 'required',
            last_name : 'required',
            address : 'required',
            city : 'required',
            country : 'required',
            post_code : 'required',
            mobile : 'required',
            email : 'required|email'
        };

        if(address.new_user_add){
            checkSet.password = 'required';
            checkSet.password_confirmation = 'required|confirm:js-password';
        }

        if($addressForm.autoCheckForm(checkSet).check()){
            var $provinceSp = $('.sp_province');
            if(!address.delivery_pid && !address.delivery_province && $.inArray($('#country-id').find("option:selected").text(), ['United States', 'Canada']) != -1){
                $provinceSp.html('please select or input you province');
                $provinceSp.css('display', 'inline');
                return false;
            }else{
                $provinceSp.html('');
                $provinceSp.css('display', 'none');
                return true;
            }
        }
        return false;
    },

    getAddress : function(){
        return shopJS.ckAddress.address;
    },

    setAddress : function(address){
        shopJS.ckAddress.address = address;
    },

    ajaxAddressSave : function(){
        let callback = arguments[1] ? arguments[1] : null;
        let address = shopJS.ckAddress.getAddressInputVal();
        let $addressErrorMessage = $('.address-message');

        $u.http.post('/checkout/address',address).then(res=>{
            let result = res.data;
            $addressErrorMessage.addClass('hide');
            if (result.code == 200 && result.data.success) {
                Cookies.set('address',JSON.stringify(result.data.address))
                if(result.data.reload){
                    location.reload();
                }else{
                    if(result.data.address.rid == undefined){
                        result.data.address.rid = 0;
                    }

                    if (result.data.cartSummaryView != undefined) {
                        $('.js-cart-summary').replaceWith(result.data.cartSummaryView)
                    }

                    if(callback && typeof callback == 'function'){
                        callback(result.data);
                    }
                }
            }

            if( result.data.message && !result.data.success ){
                $addressErrorMessage.html(result.data.message);
                $addressErrorMessage.removeClass('hide');
            }else{
                $addressErrorMessage.addClass('hide');
            }
        }).catch(err=>{
            // console.log(err);
            let res = err.response;
            let result = res.request;
            for(let key in result.responseJSON){
                if(!$addressErrorMessage.html()){
                    $addressErrorMessage.html(result.responseJSON[key][0]);
                }
            }
            $addressErrorMessage.removeClass('hide');
        })
    },

    ajaxCountryChange : function($element){
        var callback = arguments[1] ? arguments[1] : null;
        var cid = $element.val();

        shopJS.ckAddress.setCountryForMaps()

        $u.http.post('/checkout/ajaxGetProvince',{
            delivery_cid:cid
        }).then(res=>{
            let result = res.data;
            if(result.code == 200 && result.data.success){
                shopJS.ckAddress.provinces = result.data.provinces;
                shopJS.ckAddress.addressInput.deliveryPid.find('option').remove();
                if(result.data.provinces.length == 0){
                    shopJS.ckAddress.addressInput.deliveryProvince.val('');
                    shopJS.ckAddress.addressInput.deliveryProvince.removeClass('hide');
                    $('.js-province-list').addClass('hide');
                }else{
                    shopJS.ckAddress.addressInput.deliveryProvince.addClass('hide');

                    const $pIdList = shopJS.ckAddress.addressInput.deliveryPid;
                    $pIdList.append($('<option value="" selected>Please select...</option>'));
                    $.each(result.data.provinces, function(index, province){
                        $pIdList.append($('<option value="'+province.pid+'">'+province.province_name+'</option>'));
                    });

                    $('.js-province-list').removeClass('hide');
                }

                if(callback && typeof callback == 'function'){
                    callback(result.data);
                }
            }
        }).catch()
    }

    ,addAddressList : function(address){
        var $oldElement = shopJS.ckAddress.addressList.find('ul[data-rid="'+address.rid+'"]');

        var content = '<ul class="cur" data-rid="'+address.rid+'" data-addressjson=\''+JSON.stringify(address)+'\'>';
        content += '<li>'+address.delivery_first_name+' '+address.delivery_last_name+'</li>';
        content += '<li>'+address.delivery_address+', '+address.delivery_city+', '+address.delivery_province+', '+address.delivery_country+', '+address.delivery_postcode+'</li>';
        content += '<li>Phone:'+address.delivery_mobile+'</li>';
        content += '<li class="pull-right eidt clearfix"><div class="edits"><div class="editss js-address-edit">EDIT</div></div></li>';
        content += '</ul>';

        shopJS.ckAddress.addressList.find('ul').removeClass('cur');

        if($oldElement.length){
            $oldElement.replaceWith($(content));
        }else{
            shopJS.ckAddress.addressList.append($(content));
        }
        shopJS.ckAddress.addressListShow();
        shopJS.ckAddress.addressSelected.addClass('hide');
    }

    ,addressFormHide : function(){
        $('.js-address-form').addClass('hide');
        $('.js-address-continue').removeClass('hide');
        $('.js-new-address').removeClass('hide');
    }

    ,addressFormShow : function(){
        $('.js-address-form').removeClass('hide');
        $('.js-address-continue').addClass('hide');
        $('.js-new-address').addClass('hide');
    }

    ,addressListShow : function(){
        shopJS.ckAddress.addressList.removeClass('hide');
        $('.js-new-address').removeClass('hide');
    }

    ,addressReset : function(){
        if( shopJS.ckAddress.addressList.find('ul').length > 1){
            shopJS.ckAddress.addressListShow();
            $('.js-address-continue').removeClass('hide');
        }else{
            shopJS.ckAddress.setAddressInput(shopJS.ckAddress.getAddress());
            shopJS.ckAddress.addressFormShow();
        }
        $('.js-address-selected').addClass('hide');
        $('.js-address-title-edit').addClass('hide');
        shopJS.ckAddress.setAddress(undefined);
    },

    // auto complete address with google maps.
    autoCompleteOfMaps : null,

    ACResultTypesMap: {
        province:['administrative_area_level_1'],
        city:['locality'],
        postal_code:['postal_code'],
        address:['street_number','route','sublocality_level_1']
    },

    countryCode:'',

    loadGoogleMapJs(){
        console.log('load google map');
        (function(w,d,key,cb){
            let url = 'https://maps.googleapis.com/maps/api/js';
            let f=d.getElementsByTagName('script')[0];
            let j=d.createElement('script');
            j.defer=true;
            j.src=url+'?key='+key+'&callback='+cb+'&libraries=places';
            f.parentNode.insertBefore(j,f);
        })(window,document,'AIzaSyDpgIXIEFYsXvf2QjarE_rELq6kflxZyls','initGoogleMap');

        window.initGoogleMap = shopJS.ckAddress.initGoogleMap;
    },

    initGoogleMap(){
        console.log('google call back');
        const addressInput = document.getElementById("address");
        const options = {
            componentRestrictions: { country: shopJS.ckAddress.countryCode || 'us' },
            fields: ["address_components"],
            types: ["address"],
            strictBounds: false,
        };

        shopJS.ckAddress.autoCompleteOfMaps = new window.google.maps.places.Autocomplete(addressInput, options);
        shopJS.ckAddress.autoCompleteOfMaps.addListener("place_changed", () => {
            const place = shopJS.ckAddress.autoCompleteOfMaps.getPlace();
            // console.log('place changed Event.')
            // console.log(place)
            let address = [];
            place.address_components.forEach((i)=>{
                // console.log(i);
                switch(i.types[0]) {
                    case shopJS.ckAddress.ACResultTypesMap.city[0]:
                        $('.js-address-form #city').val(i.long_name).siblings('.is-error').text('');
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.postal_code[0]:
                        $('.js-address-form #postal').val(i.long_name).siblings('.is-error').text('');
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.address[0]:
                        address[0] = i.long_name;
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.address[1]:
                        address[1] = i.long_name;
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.address[2]:
                        address[2] = i.long_name;
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.province[0]:
                        if(shopJS.ckAddress.provinces.length > 0) {
                            let arr = shopJS.ckAddress.provinces.filter(j=>{
                                return j.province_name === i.long_name;
                            })

                            if(arr.length > 0) {
                                $('.js-address-form #province-id').val(arr[0].pid)
                                                                  .siblings('.is-error').text('');
                                return;
                            }
                        }

                        $('.js-address-form #province').val(i.long_name).siblings('.is-error').text('');
                        break;
                    default:
                        break;
                }
            })

            $('.js-address-form #address').val(address.filter(i=>!!i.trim()).join(' ')).siblings('.is-error').text('');
        });
    },

    setCountryForMaps(){
        shopJS.ckAddress.countryCode =  $('#country-id option:selected').data('code').toLowerCase();

        if(!shopJS.ckAddress.autoCompleteOfMaps) return;
        shopJS.ckAddress.autoCompleteOfMaps.setComponentRestrictions({
            country: [shopJS.ckAddress.countryCode],
        });
    }

};